import {ImageConfig} from "../../../../constants";
import "./CommonHcpDetailsCard.scss";
import HorizontalLineComponent
    from "../../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import {CommonService} from "../../../../shared/services";

interface CommonHcpDetailsCardProps {
    hcpDetails?: any;
    innerShiftDetails?: any;
    shiftDetails?: any;
    shiftType?: any;
    shiftHcpDetails?: any;
}

const CommonHcpDetailsCard = (props: CommonHcpDetailsCardProps) => {
    const {hcpDetails, shiftDetails, innerShiftDetails} = props;
    return (
        <>
            <div className={'hcp-common-card-details'}>
                <div className={'d-flex ts-align-items-center mrg-bottom-15'}>
                    <div>
                        {
                            hcpDetails?.hcp_profile_picture?.url &&
                            <img className={'profile-image-wrapper'} src={hcpDetails?.hcp_profile_picture?.url}
                                 loading="lazy" alt={'profile_image'}/>
                        }
                        {
                            (!hcpDetails?.hcp_profile_picture?.url && hcpDetails?.gender === "female") &&
                            <ImageConfig.HCPFemaleIcon/>
                        }
                        {
                            (!hcpDetails?.hcp_profile_picture?.url && hcpDetails?.gender === "male") &&
                            <ImageConfig.HCPMaleIcon/>
                        }

                        {
                            (!hcpDetails?.hcp_profile_picture?.url && hcpDetails?.gender === "other") &&
                            <ImageConfig.HCPMaleIcon/>
                        }

                    </div>
                    <div className={'mrg-left-15'}>
                        <div className={'d-flex ts-align-items-center mrg-bottom-5'}>
                            <div
                                className={'hcp-name-text'}>{hcpDetails?.first_name + " " + hcpDetails?.last_name || 'N/A'}</div>
                            <div className={'hcp-type-text mrg-left-10'}>( {hcpDetails?.hcp_type_details?.title} )</div>
                        </div>
                        <div className={'d-flex'}>
                            <div
                                className={'returning-text'}>{(shiftDetails?.hcp_status || innerShiftDetails?.hcp_status) === "Returning" ? "Returning" : "New"}&nbsp;</div>
                            <div
                                className={'agency-name-text'}>{hcpDetails?.agency_details?.name ? ` | ${hcpDetails?.agency_details?.name}` : "N/A"}</div>
                        </div>
                    </div>
                </div>
                <HorizontalLineComponent/>
                <div className={'mrg-bottom-15 mrg-top-15 d-flex d-flex-space-between'}>
                    <div className={'d-flex'}>
                        <div className={'d-flex ts-align-items-center mrg-left-10'}>
                            <div>
                                <div><ImageConfig.GenderIcon/></div>
                            </div>
                            <div className={'gender-text mrg-left-10'}>
                                {CommonService.capitalizeFirstLetter(hcpDetails?.gender)}
                            </div>
                        </div>
                        <div className={'d-flex ts-align-items-center mrg-left-10'}>
                            <div><ImageConfig.FacilityCountIcon/></div>
                            <div className={'gender-text mrg-left-10'}>
                                {hcpDetails?.hcp_shifts_count} shifts
                            </div>
                        </div>
                    </div>
                    {/*{shiftType === "application" &&*/}
                    {/*    <div className={'differential-hcp-rate mrg-right-10'}>*/}
                    {/*        Differential Rate : - {shiftHcpDetails?.differential && `$${shiftHcpDetails?.differential}`}*/}
                    {/*    </div>}*/}
                    {/*{(shiftType !== "pending" && shiftType !== "application") &&*/}
                    {/*    <div className={'differential-hcp-rate mrg-right-10'}>*/}
                    {/*        Differential Rate :*/}
                    {/*        - {innerShiftDetails?.differential && `$${innerShiftDetails?.differential}`}*/}
                    {/*    </div>}*/}
                    {/*{shiftType === "pending" &&*/}
                    {/*    <div className={'differential-hcp-rate mrg-right-10'}>*/}
                    {/*        Differential Rate : - {shiftDetails?.differential && `$${shiftDetails?.differential}`}*/}
                    {/*    </div>}*/}
                </div>
                <HorizontalLineComponent/>
            </div>

        </>
    );
};

export default CommonHcpDetailsCard;
