import "./SchedulerLegend.scss"

const SchedulerLegendComponent = () => {
    return (
        <>
            <div className="scheduler-legend-component">
                <div className="scheduler-legend-header"
                //      style={{
                //     backgroundImage: `url(${ImageConfig.SchedulerLegend}`,
                //     backgroundRepeat: "no-repeat"
                // }}
                >
                    Legend
                </div>
                <div className="scheduler-legend-body d-flex justify-content-space-between pdd-25">
                    <div>
                        <div className={'legend-header-card-text'}>Shift Timings</div>
                        <div>
                            <div className={'legend-card d-flex align-items-center mrg-top-15'}>
                                <div className={'color-box green'}></div>
                                <div className={'mrg-left-15 legend-header-card-text'}>07:00p - 02:59a</div>
                            </div>
                            <div className={'legend-card d-flex align-items-center mrg-top-15'}>
                                <div className={'color-box blue'}></div>
                                <div className={'mrg-left-15 legend-header-card-text'}>11:00p - 06:59a</div>
                            </div>
                            <div className={'legend-card d-flex align-items-center mrg-top-15'}>
                                <div className={'color-box purple'}></div>
                                <div className={'mrg-left-15 legend-header-card-text'}>03:00p - 10:59p</div>
                            </div>
                        </div>

                    </div>
                    <div>
                        <div className={'legend-header-card-text'}>Requirement Type</div>
                        <div>
                            <div className={'legend-card d-flex align-items-center mrg-top-15'}>
                                <div className={'color-box rt-openshift-color'}></div>
                                <div className={'mrg-left-15 legend-header-card-text'}>Open Shifts</div>
                            </div>
                            <div className={'legend-card d-flex align-items-center mrg-top-15'}>
                                <div className={'color-box rt-contract-travel-shift-color'}></div>
                                <div className={'mrg-left-15 legend-header-card-text'}>Contract / Travel</div>
                            </div>
                            <div className={'legend-card d-flex align-items-center mrg-top-15'}>
                                <div className={'color-box rt-hospice-shift-color'}></div>
                                <div className={'mrg-left-15 legend-header-card-text'}>Per Job / Visit</div>
                            </div>
                        </div>

                    </div>
                    <div>
                        <div className={'legend-header-card-text'}>Shift Status</div>
                        <div>
                            <div className={'legend-card d-flex align-items-center mrg-top-15'}>
                                <div className={'color-box ss-pending-status-color'}></div>
                                <div className={'mrg-left-15 legend-header-card-text'}>Pending Shifts</div>
                            </div>
                            <div className={'legend-card d-flex align-items-center mrg-top-15'}>
                                <div className={'color-box ss-approved-status-color'}></div>
                                <div className={'mrg-left-15 legend-header-card-text'}>Approved Shifts</div>
                            </div>
                            <div className={'legend-card d-flex align-items-center mrg-top-15'}>
                                <div className={'color-box ss-completed-status-color'}></div>
                                <div className={'mrg-left-15 legend-header-card-text'}>Completed Shifts</div>
                            </div>
                            <div className={'legend-card d-flex align-items-center mrg-top-15'}>
                                <div className={'color-box ss-confirmed-status-color'}></div>
                                <div className={'mrg-left-15 legend-header-card-text'}>Confirmed Shifts</div>
                            </div>
                            <div className={'legend-card d-flex align-items-center mrg-top-15'}>
                                <div className={'color-box ss-cancelled-status-color'}></div>
                                <div className={'mrg-left-15 legend-header-card-text'}>Canceled Shifts</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
export default SchedulerLegendComponent;
