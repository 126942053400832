import {ImageConfig, Misc, Patterns} from "../../../../../constants";
import "./AssignHcpCompionent.scss";
import HorizontalLineComponent
    from "../../../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import React, {useCallback} from "react";
import {ErrorMessage, Field, FieldArray, FieldProps, Form, Formik, FormikHelpers} from "formik";
import {CommonService} from "../../../../../shared/services";
import {IAPIResponseType} from "../../../../../shared/models/api.model";
import {useParams} from "react-router-dom";
import ButtonComponent from "../../../../../shared/components/button/ButtonComponent";

// import {useSelector} from "react-redux";
// import {IRootReducerState} from "../../../../../store/reducers";
import NoDataCard from "../../../../../shared/components/no-data-card-show/NoDataCardComponent";
import FormikInputComponent from "../../../../../shared/components/form-controls/formik-input/FormikInputComponent";

interface AssignHcpToShiftComponentProps {
    setOpenDrawer: any;
    setFilterData: any;
    filterData: any;
    hcpListRequirementBased: any;
    handleHcpRequiredSubmit: any;
    selectedAgencyForHcp: any;
    shiftRequirementDetails?: any;
    recall?: any;
}

const AssignHcpToShiftComponent = (props: AssignHcpToShiftComponentProps) => {
    const {
        setOpenDrawer,
        setFilterData,
        filterData,
        hcpListRequirementBased,
        handleHcpRequiredSubmit,
        selectedAgencyForHcp,
        shiftRequirementDetails,
        recall
    } = props;
    const {requirementId} = useParams();
    //   const {agencyList} = useSelector((state: IRootReducerState) => state.staticData);
    const initialValues = {
        selectedHcpIds: [], // Store selected HCP IDs
        differentialAmounts: Array(hcpListRequirementBased && hcpListRequirementBased?.length).fill(''), // Initialize with empty strings
    };

    const handleSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        const selectedApplications = values?.selectedHcpIds?.reduce((acc: any, hcpId: any, index: any) => {
            if (hcpId && hcpId.length > 0) {
                acc.push({
                    hcp_id: hcpId[0],
                    differential: values.differentialAmounts[index] || 0, // Default to 0 if not provided
                });
            }
            return acc;
        }, []);

        const payload: any = {
            // agency_id: selectedAgencyForHcp?.selectedAgency,
            applications: selectedApplications,
        };
        if (selectedAgencyForHcp?.selectedAgency) {
            payload.agency_id = selectedAgencyForHcp?.selectedAgency;
            payload.is_internal_hcp = false;
        } else {
            payload.facility_id = shiftRequirementDetails?.facility_details?.data?._id;
            payload.is_internal_hcp = true;
        }
        if (payload?.applications?.length === 0) {
            CommonService._alert.showToast("Please Select HCP", "info");
            setSubmitting(false);
            return;
        }
        CommonService._shift_management.addNewApplicationsToShiftAPICall(requirementId, payload)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                setOpenDrawer(false);
                setFilterData({...filterData, status: "pending"});
                handleHcpRequiredSubmit();
                recall(true);
            }).catch((error: any) => {
            setOpenDrawer(false);
            CommonService._alert.showToast(error?.error, "error");
        }).finally(() => {
            setSubmitting(false);
        });
    }, [selectedAgencyForHcp?.selectedAgency, requirementId, shiftRequirementDetails?.facility_details?.data?._id, setOpenDrawer, setFilterData, filterData, handleHcpRequiredSubmit, recall]);

    return (
        <div className={'assign-hcp-component'}>
            <div className={"hcp-assign-header"}>
                <div className={'form-header-text'}>
                    {selectedAgencyForHcp?.selectedAgency ? "Add Staff" : "Add Internal Staff"}
                </div>
                <div className={'icon-button'} onClick={() => {
                    setOpenDrawer(false);
                }}><ImageConfig.CrossIcon/></div>
            </div>
            <HorizontalLineComponent/>
            {(!hcpListRequirementBased || hcpListRequirementBased?.length === 0) &&
                <NoDataCard/>
            }
            <Formik
                initialValues={initialValues}
                //validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(formik) => (
                    <Form>
                        <div className={'form-container'}>
                            <FieldArray name="selectedHcpIds">
                                {(arrayHelpers) => (
                                    <div>
                                        {hcpListRequirementBased && hcpListRequirementBased?.map((item: any, index: any) => (
                                            <div key={item._id} className={'form-wrapper'}>
                                                <div className={"mrg-right-5"}>
                                                    <Field
                                                        type="checkbox"
                                                        name={`selectedHcpIds[${index}]`}
                                                        value={item._id}
                                                        style={{
                                                            // Hide the default checkbox
                                                            width: '20px',      // Set width to 30px
                                                            height: '20px',
                                                            cursor: "pointer",
                                                            // Set height to 30px, // Set the background color to red (change as needed)
                                                        }}
                                                    />
                                                </div>
                                                <div className={'flex-2'}>
                                                    {`${item.first_name} ${item.last_name}`}
                                                </div>
                                                <div className={'flex-1'}>
                                                        {
                                                            // @ts-ignore
                                                            formik.values.selectedHcpIds[index]?.length > 0 &&
                                                            <>
                                                                {selectedAgencyForHcp?.selectedAgency &&
                                                                    <Field name={`differentialAmounts[${index}]`}>
                                                                        {
                                                                            (field: FieldProps) => (
                                                                                <FormikInputComponent
                                                                                    // label={'Differential Amount'}
                                                                                    placeholder={'Differential Amount'}
                                                                                    type={"number"}
                                                                                    //   required={true}
                                                                                    formikField={field}
                                                                                    fullWidth={true}
                                                                                    validationPattern={Patterns.POSITIVE_WHOLE_NUMBERS}
                                                                                />
                                                                            )
                                                                        }
                                                                    </Field>}
                                                                <ErrorMessage
                                                                    name={`differentialAmounts[${index}]`}
                                                                    component="div"
                                                                    className="error"
                                                                />
                                                            </>
                                                        }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </FieldArray>
                        </div>
                        <div className={'center-element'}>
                            <ButtonComponent disabled={formik.isSubmitting || !formik.isValid}
                                             isLoading={formik.isSubmitting} type={'submit'} className={'mrg-top-10'}
                                             style={{backgroundColor: "#192938", color: "white"}}>
                                {selectedAgencyForHcp?.selectedAgency ? "Send Staff Application" : "Send Internal Staff Application"}
                            </ButtonComponent>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
export default AssignHcpToShiftComponent;

