import {ImageConfig} from "../../../../constants";
import {CommonService} from "../../../../shared/services";
import React from "react";
import "./shiftDetailsComponent.scss"
import HorizontalLineComponent
    from "../../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import DataLabelValueComponent from "../../../../shared/components/data-label-value/DataLabelValueComponent";

interface HomeHospiceDetailsProps {
    hospiceHomeShiftDetails?: any;
}

const HomeHospiceDetails = (props: HomeHospiceDetailsProps) => {
    const {hospiceHomeShiftDetails} = props;
    const address = [
        hospiceHomeShiftDetails?.requirement_details?.hospice_details?.apt,
        hospiceHomeShiftDetails?.requirement_details?.hospice_details?.street,
        hospiceHomeShiftDetails?.requirement_details?.hospice_details?.city,
        hospiceHomeShiftDetails?.requirement_details?.hospice_details?.state,
        hospiceHomeShiftDetails?.requirement_details?.hospice_details?.zipcode
    ].filter(Boolean).join(', ') || 'N/A';

    const shiftCreatedFor=
        [
            hospiceHomeShiftDetails?.requirement_details?.send_to?.is_internal_hcp && "Internal",
            hospiceHomeShiftDetails?.requirement_details?.send_to?.is_float && "Float",
            hospiceHomeShiftDetails?.requirement_details?.send_to?.specific_agencies && "Agency"
        ].filter(Boolean).join(', ')  ;

    return (
        <>
            <>


                <div
                    className={'shift-requirement-week-day-details-card'}>
                    <div className={'pdd-15'}>
                        <div className={'d-flex align-items-center mrg-bottom-15'}>
                            <div
                                className={'shift-requirement-week-day-header form-header-text d-flex ts-justify-content-center'}>Shift
                                Requirement
                                Details
                            </div>
                            <div className={'shift-for-text mrg-left-5'}>
                                {
                                    hospiceHomeShiftDetails?.requirement_details?.send_to &&
                                    <div className={'d-flex ts-align-items-center'}>(Shift
                                        created for :&nbsp;
                                        {shiftCreatedFor})
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={'d-flex mrg-bottom-20'}>
                            <div
                                className={'d-flex flex-2 ts-align-items-center'}>
                                <div
                                    className={"d-flex ts-align-items-center"}>
                                    <ImageConfig.HCPCountIcon/>
                                </div>
                                <div>
                                    <div className={'text-patient-name'}>
                                        {hospiceHomeShiftDetails?.requirement_details?.hospice_details?.first_name}&nbsp;{hospiceHomeShiftDetails?.requirement_details?.hospice_details?.middle_name}
                                        &nbsp;{hospiceHomeShiftDetails?.requirement_details?.hospice_details?.last_name}
                                    </div>
                                    <div>
                                        ({hospiceHomeShiftDetails?.requirement_details?.hospice_details?.gender} {hospiceHomeShiftDetails?.requirement_details?.hospice_details?.age || 0} years)
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'d-flex mrg-bottom-20'}>
                            <div
                                className={'d-flex  flex-2 ts-align-items-center'}>
                                <div
                                    className={"d-flex mrg-right-15 ts-align-items-center"}>
                                    <ImageConfig.HCPTypeIcon/>
                                </div>
                                <div>
                                    {hospiceHomeShiftDetails?.hcpTypeDetails?.title} {hospiceHomeShiftDetails?.hcp_speciality_details?.title && `(${hospiceHomeShiftDetails?.hcp_speciality_details?.title})`}
                                    &nbsp; {hospiceHomeShiftDetails?.requirement_details?.gender_preference && `Req for: (${hospiceHomeShiftDetails?.requirement_details?.gender_preference} Staff)`}
                                </div>
                            </div>
                        </div>
                        <div className={'d-flex mrg-bottom-20'}>
                            <div
                                className={'d-flex flex-2 ts-align-items-center'}>
                                <div
                                    className={"d-flex mrg-right-15 ts-align-items-center"}>
                                    <ImageConfig.FacilityIcon/>
                                </div>
                                <div>
                                    {hospiceHomeShiftDetails?.facilityDetails?.name || 'N/A'}
                                </div>
                            </div>
                        </div>
                        <div className={'d-flex mrg-bottom-20'}>
                            <div
                                className={'d-flex flex-2 ts-align-items-center'}>
                                <div
                                    className={"d-flex mrg-right-15 ts-align-items-center"}>
                                    <ImageConfig.CalenderIconSmall/>
                                </div>
                                <div>
                                    {CommonService?.convertMinutesToTime(hospiceHomeShiftDetails?.expected?.shift_start_time)} - {CommonService?.convertMinutesToTime(hospiceHomeShiftDetails?.expected?.shift_end_time)}
                                </div>
                            </div>
                        </div>
                        <div className={'d-flex mrg-bottom-20'}>
                            <div
                                className={'d-flex  flex-2 ts-align-items-center'}>
                                <div
                                    className={"d-flex mrg-right-15 ts-align-items-center"}>
                                    <ImageConfig.CalenderIconSmall/>
                                </div>
                                <div>
                                    {CommonService.convertDateFormat2(hospiceHomeShiftDetails?.shift_date)}
                                </div>
                            </div>
                        </div>
                        <HorizontalLineComponent/>
                        <div className={'d-flex align-items-center mrg-bottom-15 mrg-top-15'}>
                            <div
                                className={'shift-requirement-week-day-header form-header-text d-flex ts-justify-content-center'}>Patient
                                Address
                            </div>
                        </div>
                        <div className={'d-flex mrg-bottom-20'}>
                            <div
                                className={'d-flex flex-2 ts-align-items-center'}>
                                <div
                                    className={"d-flex mrg-right-15 ts-align-items-center"}>
                                    <ImageConfig.LocationIcon/>
                                </div>
                                <div>
                                    {address}
                                </div>
                            </div>
                        </div>
                        <HorizontalLineComponent/>
                        <div className={'d-flex align-items-center mrg-bottom-15 mrg-top-15'}>
                            <div
                                className={'shift-requirement-week-day-header form-header-text d-flex ts-justify-content-center'}>Patient
                                Contact
                                Details
                            </div>
                        </div>
                        <div className={'ts-row mrg-top-15'}>
                            <div className={'ts-col-lg-5'}>
                                <DataLabelValueComponent label={'Phone Number'}>
                                    {CommonService.formatPhoneNumber(hospiceHomeShiftDetails?.requirement_details?.hospice_details?.phone_number?.replace(/^\+1/, '')) || 'N/A'}
                                </DataLabelValueComponent>
                            </div>
                            <div className={'ts-col-lg-7'}>
                                <DataLabelValueComponent label={'Email Address'}>
                                    {hospiceHomeShiftDetails?.requirement_details?.hospice_details?.email_address || "N/A"}
                                </DataLabelValueComponent>
                            </div>
                        </div>
                        <HorizontalLineComponent/>
                        <div className={'d-flex align-items-center mrg-bottom-15 mrg-top-15'}>
                            <div
                                className={'shift-requirement-week-day-header form-header-text d-flex ts-justify-content-center'}>More
                                Details
                            </div>
                        </div>
                        <div className={'ts-row mrg-top-15 '}>
                            <div className={'ts-col-lg-5'}>
                                <DataLabelValueComponent label={'Visit Rate'}>
                                    $ {hospiceHomeShiftDetails?.shift_rate || 'N/A'}
                                </DataLabelValueComponent>
                            </div>
                            <div className={'ts-col-lg-7'}>
                                <DataLabelValueComponent label={'Number of Visits'}>
                                    {hospiceHomeShiftDetails?.requirement_details?.number_of_visit || "N/A"}
                                </DataLabelValueComponent>
                            </div>
                        </div>
                        <div className={'ts-row  '}>
                            <div className={'ts-col-lg-5'}>
                                <DataLabelValueComponent label={'MRN'}>
                                    {hospiceHomeShiftDetails?.requirement_details?.hospice_details?.mrn || 'N/A'}
                                </DataLabelValueComponent>
                            </div>
                            <div className={'ts-col-lg-7'}>
                                <DataLabelValueComponent label={'Number of Staff Required'}>
                                    {hospiceHomeShiftDetails?.requirement_details?.required_hcp_count || "N/A"}
                                </DataLabelValueComponent>
                            </div>
                        </div>
                        <div className={'ts-row'}>
                            <div className={'ts-col-lg-5'}>
                                <DataLabelValueComponent label={'Latitude'}>
                                    {hospiceHomeShiftDetails?.requirement_details?.hospice_details?.location?.coordinates[1] || 'N/A'}
                                </DataLabelValueComponent>
                            </div>
                            <div className={'ts-col-lg-7'}>
                                <DataLabelValueComponent label={'Longitude'}>
                                    {hospiceHomeShiftDetails?.requirement_details?.hospice_details?.location?.coordinates[0] || "N/A"}
                                </DataLabelValueComponent>
                            </div>
                        </div>
                        <div className={'ts-row '}>
                            <div className={'ts-col-lg-7'}>
                                <DataLabelValueComponent label={'Languages Preferences'}>
                                    <p>{hospiceHomeShiftDetails?.requirement_details?.languages ? hospiceHomeShiftDetails?.requirement_details?.languages?.filter((language: any) => language !== null)
                                        .join(', ') : "N/A"}</p>
                                    {/*{hospiceHomeShiftDetails?.requirement_details?.hospice_details?.email_address || "-"}*/}
                                </DataLabelValueComponent>
                            </div>
                        </div>
                        <HorizontalLineComponent/>
                        <div className={'d-flex align-items-center mrg-bottom-15 mrg-top-15'}>
                            <div
                                className={'shift-requirement-week-day-header form-header-text d-flex ts-justify-content-center'}>
                                Additional Details
                            </div>
                        </div>

                        {hospiceHomeShiftDetails?.requirement_details?.requirement_docs && hospiceHomeShiftDetails?.requirement_details?.requirement_docs?.length > 0 &&
                            <div className={'ts-row'}>
                                <div className={'ts-col-lg-12'}>
                                    <DataLabelValueComponent label={'Documents '}>
                                        <div>
                                            {hospiceHomeShiftDetails?.requirement_details?.requirement_docs && hospiceHomeShiftDetails?.requirement_details?.requirement_docs?.map((file: any, index: any) => {
                                                return (
                                                    <>
                                                        <div
                                                            className={'file-holder-wrapper mrg-bottom-10 pdd-10'}>
                                                            <div
                                                                className={'underline-text icon-button'}
                                                                onClick={() => {
                                                                    const link = document.createElement('a');
                                                                    link.href = file?.url;
                                                                    link.setAttribute('download', '');

                                                                    document.body.appendChild(link);
                                                                    link.click();

                                                                    // Cleanup
                                                                    document.body.removeChild(link);
                                                                    // window.open(file?.url);
                                                                }}> Document {index + 1}</div>
                                                        </div>
                                                    </>
                                                );
                                            })}
                                        </div>
                                    </DataLabelValueComponent>
                                </div>
                            </div>}
                    </div>
                </div>
            </>
        </>
    )
}
export default HomeHospiceDetails;
