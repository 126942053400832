import TextAreaComponent from '../form-controls/text-area/TextAreaComponent';
import ButtonComponent from '../button/ButtonComponent';
import React from 'react';
import './RejectCancelComponent.scss';

interface RejectCancelledCommonComponentProps {
  setOpenDialog: any;
  cancellationType: any;
  setShiftCancellationReason: any;
  onSubmit: any;
  shiftCancellationReason: any;
  isLoading: any;
  setLoadingOption?: any;
}

const RejectCancelledCommonComponent = (
  props: RejectCancelledCommonComponentProps,
) => {
  const {
    setOpenDialog,
    setShiftCancellationReason,
    onSubmit,
    shiftCancellationReason,
    isLoading,
    cancellationType,
    setLoadingOption,
  } = props;

  return (
    <>
      <div
        className={'reject-cancelled-component'}
        // style={{
        //   backgroundImage: `url(${ImageConfig.CancelPopupGradient}`,
        //   backgroundRepeat: 'no-repeat',
        // }}
      >
        <div
          className={
            'mrg-top-45 d-flex ts-justify-content-center reject-upper-text mrg-bottom-15'
          }
        >
          Please tell us why?
        </div>
        <div className={'d-flex ts-justify-content-center reject-lower-text'}>
          Please type in the reason for the
        </div>
        <div className={'d-flex ts-justify-content-center reject-lower-text'}>
          shift{' '}
          {cancellationType === 'Reject Shift' ? 'rejection' : 'cancellation'}
        </div>
        <div className={'mrg-right-45 mrg-left-45 mrg-bottom-35 mrg-top-15'}>
          <div>
            <TextAreaComponent
              required={true}
              placeholder={`Enter your ${
                cancellationType === 'Reject Shift'
                  ? 'rejection'
                  : 'cancellation'
              } reason`}
              rows={8}
              fullWidth
              label={''}
              onChange={(e) => {
                setShiftCancellationReason(e);
              }}
            />
          </div>
          <div className={'d-flex ts-justify-content-sm-between'}>
            <>
              <div className={'d-flex center-the-element-content'}>
                <ButtonComponent
                  onClick={() => {
                    setOpenDialog(false);
                    setLoadingOption(null);
                  }}
                  color={'download-button'}
                  id={'no-btn'}
                  className={'pdd-left-60 pdd-right-60'}
                >
                  {'No, Go Back'}
                </ButtonComponent>
                &nbsp;&nbsp;&nbsp;
              </div>
            </>

            <div className={'d-flex center-the-element-content'}>
              <ButtonComponent
                disabled={!shiftCancellationReason || isLoading}
                isLoading={isLoading}
                onClick={onSubmit}
                id={'yes-btn'}
                className={
                  'pdd-left-60 pdd-right-60 confirmation-dialog-yes-btn'
                }
              >
                {cancellationType === 'Reject Shift'
                  ? 'Reject Shift'
                  : 'Cancel Shift'}
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RejectCancelledCommonComponent;
