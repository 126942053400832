import "./SchedulerFilterDrawerComponent.scss";
import {ImageConfig} from "../../../constants";
import React, {useCallback, useEffect, useState} from "react";
import HorizontalLineComponent
    from "../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import {makeStyles} from "@mui/styles";
import {Autocomplete} from "@mui/lab";
import {Paper, TextField} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {IRootReducerState} from "../../../store/reducers";
import SearchCheckboxComponent from "../../../shared/components/search-checkbox/SearchCheckboxComponent";
import {getWingsListLite} from "../../../store/actions/static-data.action";
import {open_shift_status, shift_status} from "../../../data";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";


interface SchedulerFilterDrawerComponentProps{
    setOpenDrawer?: any;
    setFilterData: any;
    filterData: any;
    setSelectedFilterCount?:any;
    selectedFacility?:any;
    setSelectedFacility?:any;
    selectedHcpTypeData?:any;
    setSelectedHcpTypeData?:any;
    selectedShiftData?:any;
    setSelectedShiftData?:any;
    selectedWingsData?:any;
    setSelectedWingsData?:any;
    selectedRequirementType?:any;
    setSelectedRequirementType?:any;
}

const useStyles: any = makeStyles({
    autocomplete: {
        '& .MuiInputBase-input': {
            height: '14px', // Adjust the height value as needed
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            paddingTop: '4px', // Adjust the padding value as needed
            paddingBottom: '4px', // Adjust the padding value as needed
        },
    },
    noOptions:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        width:'100%',
    }
});

const SchedulerFilterDrawerComponent=(props:SchedulerFilterDrawerComponentProps)=>{
    const {filterData,
        setFilterData,
        setOpenDrawer,
        setSelectedFilterCount,
        selectedFacility,
        setSelectedFacility,
        selectedHcpTypeData,
        setSelectedHcpTypeData,
        selectedShiftData,
        setSelectedShiftData,
        selectedWingsData,
        setSelectedWingsData,
        selectedRequirementType,
        setSelectedRequirementType,
    }=props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const {HcpTypesList} = useSelector((state: IRootReducerState) => state.meta);
    const {facilityList, wingsList, isWingsLoading} = useSelector((state: IRootReducerState) => state.staticData);
    const [modifiedWingsList, setModifiedWingsList] = useState<any>(null);

    const updateFilters = useCallback((updatedFilterData:any) => {
        let selectedFilter = 0;
        selectedFilter += (updatedFilterData.hcp_types || []).length;
        selectedFilter += updatedFilterData.facility_id ? 1 : 0;
        selectedFilter += (updatedFilterData.statuses || []).length;
        selectedFilter += (updatedFilterData.open_status_type || []).length;
        selectedFilter += (updatedFilterData.facility_wings || []).length;

        setSelectedFilterCount(selectedFilter);
        setFilterData(updatedFilterData);
    }, [setFilterData, setSelectedFilterCount]);

    const handleHcpTypeSelection = useCallback((selectedOptions:any) => {
        const updatedFilterData = { ...filterData, hcp_types: selectedOptions.map((item:any) => item.code) };
        updateFilters(updatedFilterData);
        setSelectedHcpTypeData(selectedOptions);
    }, [filterData, updateFilters,setSelectedHcpTypeData]);

    const handleRequirementTypeSelection = useCallback((selectedOptions:any) => {
        const updatedFilterData = { ...filterData, open_status_type: selectedOptions.map((item:any) => item.code) };
        updateFilters(updatedFilterData);
        setSelectedRequirementType(selectedOptions);
    }, [filterData, updateFilters,setSelectedRequirementType]);

    const handleShiftTypeSelection = useCallback((selectedOptions:any) => {
        const updatedFilterData = { ...filterData, statuses: selectedOptions.map((item:any) => item.code) };
        updateFilters(updatedFilterData);
        setSelectedShiftData(selectedOptions);
    }, [filterData, updateFilters,setSelectedShiftData]);

    const handleWingsSelection = useCallback((selectedOptions:any) => {
        const updatedFilterData = { ...filterData, facility_wings: selectedOptions.map((item:any) => item.code) };
        updateFilters(updatedFilterData);
        setSelectedWingsData(selectedOptions);
    }, [filterData, updateFilters,setSelectedWingsData]);

    const handleFacilitySelect = useCallback((event:any, selectedFacility:any) => {
        const updatedFilterData = { ...filterData, facility_id: selectedFacility ? selectedFacility.code : null };
        updateFilters(updatedFilterData);
        setSelectedFacility(selectedFacility);
        setSelectedWingsData([]);
    }, [filterData, updateFilters,setSelectedFacility,setSelectedWingsData]);

    useEffect(() => {
        dispatch(getWingsListLite(selectedFacility?.code));
    }, [dispatch, selectedFacility]);

    useEffect(() => {
        if (wingsList) {
            const modifiedData = wingsList?.map((item: any) => {
                const {_id, name, ...rest} = item;

                return {
                    ...rest,
                    title: name,
                    code: _id,
                };
            });
            setModifiedWingsList(modifiedData);
        } else {
            setModifiedWingsList(null);
        }
    }, [wingsList]);


    const clearAllFilter = useCallback(() => {
        setSelectedHcpTypeData([]);
        setSelectedShiftData([]);
        setSelectedRequirementType([]);
        setSelectedFacility(null);
        setSelectedWingsData([]);
        setSelectedFilterCount(0);
        delete filterData.statuses;
        delete filterData.shift_types;
        delete filterData.hcp_types;
        delete filterData.facility_wings;
        delete filterData.facility_id;
        delete filterData.open_status_type;
        setFilterData({
            ...filterData
        });
    }, [filterData,
        setFilterData,
        setSelectedFacility,
        setSelectedFilterCount,
        setSelectedHcpTypeData,
        setSelectedRequirementType,
        setSelectedShiftData,
        setSelectedWingsData
        ]);



    return (
        <div className={'scheduler-filter-drawer-container'}>
            <div className={'d-flex justify-content-space-between pdd-20'}>
                <div className={'d-flex ts-align-items-center'}>
                    <div className={'form-header-text'}> Scheduler Filters
                    </div>
                    <ButtonComponent
                        type={'reset'}
                        variant={'link'}
                        style={{
                            color:'#0DB9D8'
                        }}
                        className={'mrg-right-20'}
                        onClick={clearAllFilter}
                    >
                        Clear All
                    </ButtonComponent>
                </div>
                <div className={'d-flex'}>
                    <div className={'icon-button'} onClick={() => {
                        setOpenDrawer(false);
                    }}>
                        <ImageConfig.CrossIcon/>
                    </div>
                </div>
            </div>
            <HorizontalLineComponent/>
            <div className={'scheduler-filter-container'}>
                <div>
                    <Autocomplete
                        disableClearable={false}
                        PaperComponent={({children}) => (
                            <Paper style={{color: '#1e1e1e'}}>
                                {children}
                            </Paper>
                        )}
                        noOptionsText={"No Facility Found"}
                        value={selectedFacility}
                        options={facilityList}
                        getOptionLabel={(option: any) => option.title}
                        placeholder={'Select Facility'}
                        id='input_select_fac_payment_status'
                        classes={{
                            root: classes.autocomplete,
                            noOptions: classes.noOptions,
                        }}
                        onChange={handleFacilitySelect}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                id='select_fac_payment_status'
                                variant='outlined'
                                placeholder={'Select Facility'}
                            />
                        )}
                    />
                </div>
                <div>
                    <SearchCheckboxComponent
                        options={HcpTypesList}
                        placeholder={"Select Staff Type"}
                        selectedOptions={selectedHcpTypeData}
                        onChange={handleHcpTypeSelection}
                        noDataText={"No Staff Type"}
                    />
                </div>
                <div>
                    <SearchCheckboxComponent
                        options={open_shift_status}
                        placeholder={"Select Requirement Status"}
                        selectedOptions={selectedRequirementType}
                        onChange={handleRequirementTypeSelection}
                        noDataText={"No Requirement Status"}
                    />
                </div>
                <div>
                    <SearchCheckboxComponent
                        options={shift_status}
                        placeholder={"Select Shift Status"}
                        selectedOptions={selectedShiftData}
                        onChange={handleShiftTypeSelection}
                        noDataText={"No Shift Status"}
                    />
                </div>
                <div>
                    <SearchCheckboxComponent
                        options={modifiedWingsList}
                        placeholder={"Select Wings"}
                        selectedOptions={selectedWingsData}
                        onChange={handleWingsSelection}
                        noDataText={"No Wing"}
                        disabled={selectedFacility?.length === 0 || isWingsLoading}
                    />
                </div>
            </div>
        </div>
    )
}

export default SchedulerFilterDrawerComponent;