import "../../../facility/add-facility/facility-details/FacilityDetailsComponent.scss";
import "./AddHcpDetailsComponent.scss";
import React, {useCallback, useEffect, useState} from "react";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import ButtonComponent from "../../../../shared/components/button/ButtonComponent";
import {ImageConfig, Misc} from "../../../../constants";
import FormikInputComponent from "../../../../shared/components/form-controls/formik-input/FormikInputComponent";
import {IAPIResponseType} from "../../../../shared/models/api.model";
import {CommonService} from "../../../../shared/services";
import FormikTextAreaComponent
    from "../../../../shared/components/form-controls/formik-text-area/FormikTextAreaComponent";
import {useNavigate} from "react-router-dom";
import FormikSelectDropdownComponent
    from "../../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent";
import {useSelector} from "react-redux";
import {IRootReducerState} from "../../../../store/reducers";
import LabelComponent from "../../../../shared/components/label/LabelComponent";
// import PhoneInputComponent from "../../../../shared/components/phoneInput/PhoneInputComponent";
import {
    addInternalHcpSteps,
    americanTimeZone,
    genderList,
    ImageSupportedFile,
    OtherFilesSupported,
} from "../../../../data";
import {INTERNAL_HCP_MANAGEMENT_LIST} from "../../../../constants/RoutesConfig";
import ToggleComponent from "../../../../shared/components/toggle-box/ToggleComponent";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers";
import FilePickerComponent from "../../../../shared/components/file-picker/FilePickerComponent";
import {TextField} from "@mui/material";
import FormikRadioButtonGroupComponent
    from "../../../../shared/components/form-controls/formik-radio-button-group/FormikRadioButtonGroupComponent";
import StepperComponent from "../../../../shared/components/stepper-component/StepperComponent";
import {HcpValidationSchema} from "../../internal-hcp-validation-schema/InternalHcpValidationSchema";
import FormikPhoneInputComponent
    from "../../../../shared/components/form-controls/formik-phone-input/FormikPhoneInputComponent";


interface HCPDetailsComponentProps {
    prev: () => void;
    setFormTab: any;
    formTab: any;
}

interface formInitialValuesFormSchema {
    agency_name: string;
    contact_number: any;
    email: string;
    about: string;
    location_url: any;
    timezone: any;
    hcp_uniqueId:string;
    address: {
        street: any,
        city: any,
        state: any,
        region: any,
        country: any,
        zip_code: any,
    };
}

const formInitialValues: formInitialValuesFormSchema = {
    agency_name: "",
    contact_number: "",
    email: "",
    about: "",
    location_url: "",
    timezone: "",
    hcp_uniqueId:"",
    address: {
        street: "",
        city: "",
        state: "",
        region: "",
        country: "",
        zip_code: "",
    }
};


const AddHcpDetailsComponent = (props: HCPDetailsComponentProps) => {
    const {setFormTab, formTab} = props;
    const [initialValues] = useState<formInitialValuesFormSchema>(formInitialValues);
    const {facilityList} = useSelector((state: IRootReducerState) => state.staticData);
    //const [buttonNameOnTabChange, setButtonNameOnTabChange] = useState<any>("Next");
    const navigate = useNavigate();
    const [onboardHcpId, setOnboardHcpId] = useState<any>(null);
    const [hcpAddActiveStep, setHcpAddActiveStep] = React.useState(0);
    const [selectedDocuments, setSelectedDocuments] = useState<any>([]);
    const {
        HcpTypesList,
    } = useSelector((state: IRootReducerState) => state.meta);
    const {regionList} = useSelector((state: IRootReducerState) => state.staticData);
    const [selectedDates, setSelectedDates] = useState<any>([]);
    const [isHcpDocumentUploaded, setIsHcpDocumentUploaded] = useState<boolean>(false);
    const {currentUser} = useSelector((state: IRootReducerState) => state.account);
    const [documentUploadingList, setDocumentUploadingList] = useState<any>(
        [{
            document_name: "Resume",
            document_type: "Resume",
            document_key: "Resume",
            expiry_date: null,
            is_expiry_date: true,
        },
            {
                document_name: "Nursing License",
                document_type: "Nursing License",
                document_key: "Nursing License",
                expiry_date: null,
                is_expiry_date: true,
            },
            {
                document_name: "State ID / Drivers License",
                document_type: "State ID / Drivers License",
                document_key: "State ID / Drivers License",
                expiry_date: null,
                is_expiry_date: true,
            },
            {
                document_name: "CPR Cert",
                document_type: "CPR Cert",
                document_key: "CPR Cert",
                expiry_date: null,
                is_expiry_date: true,
            },
            {
                document_name: "BLS Cert",
                document_type: "BLS Cert",
                document_key: "BLS Cert",
                expiry_date: null,
                is_expiry_date: true,
            },
            {
                document_name: "Vaccination Record",
                document_type: "Vaccination Record",
                document_key: "Vaccination Record",
                is_active: false,
                expiry_date: null,
                is_expiry_date: true,
            },
            {
                document_name: "Covid Vaccination Record",
                document_type: "Covid Vaccination Record",
                document_key: "Covid Vaccination Record",
                expiry_date: null,
                is_expiry_date: true,
            },
            {
                document_name: "Covid Booster",
                document_type: "Covid Booster",
                document_key: "Covid Booster",
                expiry_date: null,
                is_expiry_date: true,
            },
            {
                document_name: "Physical Exam",
                document_type: "Physical Exam",
                document_key: "Physical Exam",
                expiry_date: null,
                is_expiry_date: true,
            },
            {
                document_name: "TB Exam",
                document_type: "TB Exam",
                document_key: "TB Exam",
                expiry_date: null,
                is_expiry_date: true,
            },
            {
                document_name: "Background Check",
                document_type: "Background Check",
                document_key: "Background Check",
                expiry_date: null,
                is_expiry_date: true,
            },
            {
                document_name: "DOJ Livescan",
                document_type: "DOJ Livescan",
                document_key: "DOJ Livescan",
                expiry_date: null,
                is_expiry_date: true,
            },

            {
                document_name: "Profile Picture",
                document_type: "Profile Picture",
                document_key: "Profile Picture",
                expiry_date: null,
                is_expiry_date: true,
            },]);

    const onSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        if (formTab === "Hcp") {
            setFormTab("Address");
            setSubmitting(false);
            return;
        } else if (formTab === "Address") {
            setSubmitting(true);
            const payload = {
                ...values,
                contact_number:"+1" + values?.contact_number.replace(/\D/g,''),
            };
            let apiCall: any = {};
            apiCall = CommonService._hcp.OnboardHcpAPICall(payload);
            apiCall.then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                setOnboardHcpId(response?.data?._id);
                setFormTab("Documents-upload");
                setHcpAddActiveStep(2);
            })
                .catch((error: any) => {
                    CommonService.handleErrors(setErrors, error);
                    setFormTab("Hcp");
                }).finally(() => {
                setSubmitting(false);
            });
        }
        // eslint-disable-next-line
    }, [navigate, formTab]);

    const handleToggleSwitch = useCallback((e: any, documentKey: any) => {
        const indexToUpdate = documentUploadingList.findIndex(
            (item: any) => item.document_key === documentKey
        );
        if (indexToUpdate !== -1) {
            const updatedList = [...documentUploadingList];
            if (e === true) {
                updatedList[indexToUpdate].is_active = true;
            } else {
                updatedList[indexToUpdate].is_active = false;
                const newData = {...selectedDocuments};
                if (newData.hasOwnProperty(documentKey)) {
                    delete newData[documentKey];
                    setSelectedDocuments(newData);
                }
            }
            setDocumentUploadingList(updatedList);
        }
    }, [documentUploadingList, selectedDocuments]);

    const handleDateChange = useCallback((documentKey: any, date: any) => {
        setSelectedDates((prevSelectedDates: any) => ({
            ...prevSelectedDates,
            [documentKey]: date,
        }));
    }, []);

    const previewPDF = useCallback((docs: any) => {
        if (docs) {
            const fileURL = URL.createObjectURL(docs);
            window.open(fileURL, '_blank');
        }
    }, []);

    const handleDelete = useCallback((keyToDelete: any) => {
        const newData = {...selectedDocuments};
        const dateData = {...selectedDates};
        if (newData.hasOwnProperty(keyToDelete)) {
            delete newData[keyToDelete];
            delete dateData[keyToDelete];
            setSelectedDocuments(newData);
            setSelectedDates(dateData);
        }
    }, [selectedDates, selectedDocuments]);

    const makeDocumentUploadAPICall = useCallback(async () => {
        let documentListForApi: any = [];
        setIsHcpDocumentUploaded(true);
        const keys = Object.keys(selectedDocuments);
        keys.forEach((key: any) => {
            if (selectedDates[key]) {
                documentListForApi.push({
                    document: selectedDocuments[key],
                    expiry_date: selectedDates[key] ? CommonService?.convertDateFormat(selectedDates[key], "YYYY-MM-DD") : null,
                    attachment_type: key,
                });
            } else {
                documentListForApi.push({
                    document: selectedDocuments[key],
                    attachment_type: key,
                });
            }
        });

        let completedApiCalls = 0;
        for (const item of documentListForApi) {
            const formData = new FormData();
            formData.append('document', item.document);
            if (item.expiry_date) {
                formData.append('expiry_date', item.expiry_date);
            }
            formData.append('attachment_type', item.attachment_type);
            let apiCall: any = {};
            apiCall = CommonService._hcp.uploadHcpDocumentAPICall(onboardHcpId, formData);
            // eslint-disable-next-line no-loop-func
            apiCall.then((response: IAPIResponseType<any>) => {
                completedApiCalls++;
                if (completedApiCalls === documentListForApi.length) {
                    setIsHcpDocumentUploaded(false);
                    setSelectedDocuments([])
                    CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                    navigate(CommonService._routeConfig.InternalHcpManagementRoute());
                }
            })
                // eslint-disable-next-line no-loop-func
                .catch((error: any) => {
                    completedApiCalls++;
                    if (completedApiCalls === documentListForApi.length) {
                        navigate(CommonService._routeConfig.InternalHcpManagementRoute());
                        setIsHcpDocumentUploaded(false);
                    }
                }).finally(() => {
                // setSubmitting(false);
            });
        }
    }, [selectedDocuments, selectedDates, onboardHcpId, navigate]);

    const isStaffValidated=useCallback((errors:any)=>{
        const fieldsToValidate=[
            "facility_id",
            "first_name",
            "last_name",
            "contact_number",
            "email",
            "hcp_type",
            "staff_type",
            "hcp_uniqueId"
        ]
        return fieldsToValidate.every((field:any)=>!errors[field]);
    },[])

    return (
        <div className={'add-internal-hcp-details-component'}>
            <div className={'add-hcp-sidebar-steper'}>
                <div className={'add-facility-sidebar-steper-wrapper pdd-20'}>
                    <div className={'flex-1'}>
                        <div className={'adding-module-branding-wrapper'}>
                            <div className="menu-item-icon">
                                {currentUser?.role !== "super_admin" &&
                                    <img className={'logo-customer-container'} width={25} height={25}
                                         src={currentUser?.doc_details?.url} alt=""/>}
                                {currentUser?.role === "super_admin" &&
                                    <ImageConfig.VarsShortLogoIcon/>}
                            </div>

                            <div className={'vars-health-logo-text'}>
                                {currentUser?.role !== "super_admin" &&
                                    <div>
                                        {currentUser?.logo_name}
                                    </div>}
                                {currentUser?.role === "super_admin" &&
                                    <div>
                                        Work OS
                                    </div>}

                            </div>
                        </div>
                        <div className={'form-top-header-text mrg-top-40'}>
                            Internal Staff Onboarding
                        </div>
                        <StepperComponent activeStep={hcpAddActiveStep} steps={addInternalHcpSteps}/>
                    </div>
                    <div>
                        <ButtonComponent
                            id={"exit_btn"}
                            suffixIcon={<ImageConfig.ExitIcon/>}
                            color={"exit-onboarding-navigation"}
                            onClick={() => {
                                // navigate(FACILITY_lIST);
                                CommonService.onExitFlowHandling(navigate, INTERNAL_HCP_MANAGEMENT_LIST);
                            }}
                        >
                            Exit Internal Staff Onboarding
                        </ButtonComponent>
                    </div>
                </div>
            </div>
            <div className={'add-hcp-formik-wrapper mrg-top-20'}>
                {(formTab === "Hcp" || formTab === "Address") &&
                    <Formik
                        validationSchema={HcpValidationSchema}
                        initialValues={initialValues}
                        validateOnChange={false}
                        validateOnBlur={true}
                        enableReinitialize={true}
                        validateOnMount={true}
                        onSubmit={onSubmit}
                    >
                        {({values, errors, validateForm, isValid, setFieldValue, isSubmitting}) => {
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            useEffect(() => {
                                validateForm();
                            }, [validateForm, values]);
                            return (
                                <Form noValidate={true}>
                                    <div className="add-component-content">
                                        <div className={'add-form-wrapper-box pdd-left-25 pdd-right-25 pdd-top-10'}>
                                            {formTab === "Hcp" && <>
                                                <div className={'form-top-header-text'}>Internal Staff Details</div>
                                                <Field name={'facility_id'}>
                                                    {
                                                        (field: FieldProps) => (
                                                            <FormikSelectDropdownComponent
                                                                searchable={true}
                                                                options={facilityList}
                                                                label={'Facility Name'}
                                                                placeholder={'Select'}
                                                                noDataMessage={"No Facility Found"}
                                                                required={true}
                                                                formikField={field}
                                                                fullWidth={true}
                                                            />
                                                        )
                                                    }
                                                </Field>
                                                <div className="ts-row">
                                                    <div className="ts-col-6">
                                                        <Field name={'first_name'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'First Name'}
                                                                        placeholder={'ex : John'}
                                                                        type={"text"}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className="ts-col-6">
                                                        <Field name={'last_name'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'Last Name'}
                                                                        placeholder={'ex : Doe'}
                                                                        type={"text"}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className="ts-row">
                                                    <div className="ts-col-6">
                                                        <LabelComponent title={'Phone Number*'}/>
                                                        <Field name={'contact_number'}>
                                                            {/*{(field: FieldProps) => {*/}
                                                            {/*    return <PhoneInputComponent field={field}*/}
                                                            {/*                                placeholder={"Enter Phone number"}/>;*/}
                                                            {/*}}*/}
                                                            {(field:FieldProps)=>(
                                                                <FormikPhoneInputComponent
                                                                    placeholder={"Enter Phone number"}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                    required={true}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className="ts-col-6">
                                                        <Field name={'email'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'Email Address'}
                                                                        placeholder={'example@xyz.com'}
                                                                        type={"text"}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className="ts-row">
                                                    <div className="ts-col-6">
                                                        <Field name={'hcp_type'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikSelectDropdownComponent
                                                                        onUpdate={(hcp_type_id) => {
                                                                            setFieldValue("hcp_type_id", hcp_type_id);
                                                                        }}
                                                                        valueExtractor={(option: any) => option._id}
                                                                        options={HcpTypesList}
                                                                        label={'Internal Staff Types'}
                                                                        placeholder={'Select'}
                                                                        required={true}
                                                                        searchable={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className="ts-col-6">
                                                        <Field name={'gender'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikSelectDropdownComponent
                                                                        options={genderList}
                                                                        label={'Gender'}
                                                                        placeholder={'Select'}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className={"mrg-top-10"}>
                                                    <Field name={'staff_type'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikRadioButtonGroupComponent
                                                                    options={[{
                                                                        title: 'Internal',
                                                                        code: 'internal'
                                                                    }, {title: 'Floating', code: 'floating'}]}
                                                                    formikField={field}
                                                                    valueExtractor={(option: any) => option.code}
                                                                    onChange={(e) => {
                                                                        if (e === "floating") {
                                                                            setFieldValue("is_float", true);
                                                                            setFieldValue("is_internal", false);
                                                                        } else if (e === "internal") {
                                                                            setFieldValue("is_internal", true);
                                                                            setFieldValue("is_float", false);
                                                                        }
                                                                    }}
                                                                    label={"Staff Type*"}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                                <div className={'ts-row'}>
                                                    <div className={'ts-col-12'}>
                                                        <Field name={'hcp_uniqueId'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'Staff ID'}
                                                                        placeholder={'ex : 1234'}
                                                                        type={"text"}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className={"mrg-top-10"}>
                                                    <Field name={'about'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikTextAreaComponent
                                                                    label={'About The Internal Staff'}
                                                                    placeholder={''}
                                                                    //required={true}
                                                                    rows={6}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                            </>}
                                            {
                                                formTab === "Address" && <>
                                                    <div className={'form-top-header-text'}>Internal Staff Address Details
                                                    </div>
                                                    <Field name={'address.street'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'Address Line'}
                                                                    placeholder={'Enter address here'}
                                                                    type={"text"}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                    <div className="ts-row">
                                                        <div className="ts-col-6">
                                                            <Field name={'address.country'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'Country'}
                                                                            placeholder={'Enter the Country'}
                                                                            type={"text"}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                        <div className="ts-col-6">
                                                            <Field name={'address.state'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikSelectDropdownComponent
                                                                            options={regionList}
                                                                            displayWith={(option: any) => option.title}
                                                                            valueExtractor={(option: any) => option.code}
                                                                            label={'State'}
                                                                            placeholder={'Select State'}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                            searchable={true}
                                                                            noDataMessage={"No state available"}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                    </div>
                                                    <div className="ts-row">
                                                        <div className="ts-col-6">
                                                            <Field name={'address.zip_code'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'ZIP Code'}
                                                                            placeholder={'Enter ZIP Code'}
                                                                            type={"number"}
                                                                            maxLength={6}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                        <div className="ts-col-6">
                                                            <Field name={'address.city'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'City'}
                                                                            placeholder={'Enter City'}
                                                                            type={"text"}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                    </div>
                                                    <div className="ts-row">
                                                        <div className="ts-col-6">
                                                            <Field name={'timezone'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikSelectDropdownComponent
                                                                            options={americanTimeZone}
                                                                            displayWith={(option: any) => option.title}
                                                                            valueExtractor={(option: any) => option.code}
                                                                            label={'Timezone'}
                                                                            placeholder={'Select Timezone'}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                            searchable={true}
                                                                            noDataMessage={"No timezone available"}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div className={'add-hcp-form-action-element'}>
                                            {formTab === "Hcp" && <>
                                                <div></div>
                                                <ButtonComponent
                                                    disabled={!isStaffValidated(errors)}
                                                    isLoading={isSubmitting}
                                                    onClick={() => {
                                                        setFormTab("Address");
                                                        setHcpAddActiveStep(1);
                                                    }
                                                    }
                                                    style={{color: "black"}}
                                                    suffixIcon={<ImageConfig.NextButtonIcon/>}
                                                >
                                                    Next
                                                </ButtonComponent>
                                            </>}
                                            {formTab !== "Hcp" &&
                                                <>
                                                    <div className={"mrg-left-20"}>
                                                        <ButtonComponent
                                                            className={'back-button'}
                                                            suffixIcon={<ImageConfig.BackArrow/>}
                                                            onClick={() => {
                                                                setFormTab("Hcp");
                                                                setHcpAddActiveStep(0);
                                                            }}
                                                        >
                                                            Back
                                                        </ButtonComponent>
                                                    </div>
                                                    <ButtonComponent
                                                        disabled={isSubmitting || !isValid}
                                                        isLoading={isSubmitting}
                                                        type={'submit'}
                                                        style={{color: "black"}}
                                                        suffixIcon={<ImageConfig.ArrowRightCircleIcon/>}
                                                    >
                                                        Next
                                                    </ButtonComponent>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>}
                {formTab === "Documents-upload" && <>
                    <div>
                        <div className={'add-form-wrapper-box pdd-left-25 pdd-right-25 pdd-top-10'}>
                            <div className={'form-top-header-text'}>Document Upload</div>
                            {
                                documentUploadingList?.map((document: any, index: any) => {
                                    return (
                                        <>
                                            <div key={document?.document_key} className={'document-upload-box d-flex'}>
                                                <div className={'flex-1'}>
                                                    {document?.document_name}
                                                </div>
                                                <div className={'flex-1'}>
                                                    {
                                                        <ToggleComponent onToggle={(e) => {
                                                            handleToggleSwitch(e, document.document_key);
                                                        }}></ToggleComponent>
                                                    }
                                                </div>
                                                <div className={'flex-1'}>
                                                    {
                                                        (document?.is_expiry_date && selectedDocuments[document.document_key]) &&
                                                        < div className={"mrg-left-10"}>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DatePicker
                                                                    inputFormat="MM/dd/yyyy"
                                                                    value={selectedDates[document.document_key] || null}
                                                                    onChange={(date) => handleDateChange(document.document_key, date)}
                                                                    renderInput={(params) => <TextField
                                                                        InputLabelProps={{
                                                                            shrink: true, // This makes the label stay at the top
                                                                        }} {...params}
                                                                        label={`${document?.document_name} Expiry Date`}
                                                                    />
                                                                    }
                                                                />
                                                            </LocalizationProvider>
                                                        </div>
                                                    }
                                                </div>
                                                <div className={'flex-1 d-flex justify-content-right'}>
                                                    {(!selectedDocuments[document.document_key] && document?.is_active) &&
                                                        <FilePickerComponent maxFileCount={1}
                                                                             btnText={"Upload"}
                                                                             height={'470px'}
                                                                             id={selectedDocuments[document.document_key]}
                                                                             showDropZone={false}
                                                                             onFilesDrop={(acceptedFiles: any, rejectedFiles: any) => {
                                                                                 if (acceptedFiles && acceptedFiles.length > 0) {
                                                                                     const file = acceptedFiles[0];
                                                                                     //setTimesheetFile(file);
                                                                                     setSelectedDocuments((prevSelectedDates: any) => ({
                                                                                         ...prevSelectedDates,
                                                                                         [document?.document_key]: file,
                                                                                     }));
                                                                                 }
                                                                             }}
                                                                             acceptedFileTypes={(document?.document_name !== "Profile Picture") ? OtherFilesSupported : ImageSupportedFile}
                                                                             uploadDescription={"(upload only one file)"}
                                                                             acceptedFilesText={"Only .pdf files are supported"}
                                                        />}
                                                    {(selectedDocuments[document.document_key] && document?.is_active) && <>
                                                        <div className={'file-preview-delete-wrapper'}>
                                                            <div className={'file-text icon-button'} onClick={() => {
                                                                previewPDF(selectedDocuments[document.document_key]);
                                                            }}>
                                                                {document?.document_name}
                                                            </div>
                                                            <div className={'delete-box'} onClick={() => {
                                                                handleDelete(document.document_key);
                                                            }}>
                                                                <ImageConfig.DeleteIconPreview/>
                                                            </div>
                                                            <div className={'preview-box'} onClick={() => {
                                                                previewPDF(selectedDocuments[document.document_key]);
                                                            }}>
                                                                <ImageConfig.PreviewIconPdf/>
                                                            </div>
                                                        </div>
                                                    </>}
                                                </div>
                                            </div>
                                        </>
                                    )
                                        ;
                                })
                            }
                        </div>
                        <>
                            <div className={'add-hcp-form-action-element'}>
                                <div className={"mrg-left-20"}>
                                </div>
                                {!(Object.keys(selectedDocuments).length > 0 || selectedDocuments?.length > 0) ?
                                    <ButtonComponent
                                        disabled={Object.keys(selectedDocuments).length > 0 || selectedDocuments?.length > 0}
                                        className={'mrg-left-15'}
                                        onClick={() => {
                                            navigate(CommonService._routeConfig.InternalHcpManagementRoute());
                                        }}
                                        style={{backgroundColor: "#192938", color: "white"}}
                                        suffixIcon={<ImageConfig.ArrowRightCircleIcon/>}
                                    >
                                        Skip
                                    </ButtonComponent> :
                                    <ButtonComponent
                                        onClick={makeDocumentUploadAPICall}
                                        disabled={Object.keys(selectedDocuments).length === 0 || selectedDocuments?.length === 0}
                                        isLoading={isHcpDocumentUploaded}
                                        style={{backgroundColor: "#192938", color: "white"}}
                                        suffixIcon={<ImageConfig.ArrowRightCircleIcon/>}
                                    >
                                        Next
                                    </ButtonComponent>}
                            </div>
                        </>
                    </div>
                </>}
            </div>
        </div>
    );

};

export default AddHcpDetailsComponent;
