import "./FacilityListComponent.scss";
// import TableWrapperComponent from "../../../shared/components/table-wrapper/TableWrapperComponent";
import {APIConfig, ImageConfig, Misc} from "../../../constants";
// import {ITableColumn} from "../../../shared/models/table.model";
import {CommonService} from "../../../shared/services";
// import TagComponent from "../../../shared/components/tag/TagComponent";
import {useCallback, useEffect, useMemo, useState} from "react";
import SearchComponent from "../../../shared/components/search/SearchComponent";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import LinkComponent from "../../../shared/components/link/LinkComponent";
// import ToolTipComponent from "../../../shared/components/tool-tip/ToolTipComponent";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentNavParams} from "../../../store/actions/navigation.action";
import {IAPIResponseType} from "../../../shared/models/api.model";
import CustomToggleComponent
    from "../../../shared/components/custom-toogle-using-button-component/CustomToggleComponent";
import {getFacilityListLite} from "../../../store/actions/static-data.action";
import {IRootReducerState} from "../../../store/reducers";
import AntTableComponent from "../../../shared/components/antd-table-component/AntTableComponent";
import {TableColumnsType} from "antd";
import ToolTipComponent from "../../../shared/components/tool-tip/ToolTipComponent";

interface PropertyListComponentProps {

}

const FacilityListComponent = (props: PropertyListComponentProps) => {
    const [filterData, setFilterData] = useState<any>({
        search: '',
        // sort:{},
    });
    const dispatch = useDispatch();
    const {currentUser} = useSelector((state: IRootReducerState) => state.account);
 //   const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    useEffect(() => {
        dispatch(setCurrentNavParams('Facility') as any);
    }, [dispatch]);

    const [isDownloading, setIsDownloading] = useState<boolean>(false);


    const handleFacilityListDownload = useCallback(() => {
        setIsDownloading(true);
        const payload: any = {};
        const {status} = filterData;
        payload.status = [status];
        let apiCall: any = {};
        apiCall = CommonService._facility.downloadFacilityDataCSVAPICall(filterData);

        apiCall.then((response: IAPIResponseType<any>) => {
            if (response?.data) {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                const link = document.createElement("a");
                link?.setAttribute("href", response?.data);
                document.body.appendChild(link);
                link.click();
                setIsDownloading(false);
            } else {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "error");
                setIsDownloading(false);
            }

        })
            .catch((error: any) => {
                setIsDownloading(false);
            }).finally(() => {

        });
    }, [filterData]);


    const onFacilityActivateDeactivate = useCallback((item: any) => {
        CommonService.onConfirm(
            {
                confirmationTitle: `${item?.is_active ? 'Deactivate' : 'Activate'}  Facility`,
                image: item?.is_active ? ImageConfig.DeactivateFacilityIcon : ImageConfig.ActivateFacilityIcon,
                confirmationDescription: `Are you sure you want to ${item?.is_active ? 'Deactivate' : 'Activate'}  the
    facility?`,
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                activationType: item?.is_active,
                yes: {
                    color: "error",
                    text: `Yes, ${item?.is_active ? 'Deactivate' : 'Activate'}`,
                    variant: "contained",
                }
            }
        ).then((result: any) => {
            const payload: any = {};
            payload.is_active = !item?.is_active;
            CommonService._facility.ActivateDeactivateFacility(item?.facility_details?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                    setFilterData({});
                    dispatch(getFacilityListLite());
                }).catch((error: any) => {
                CommonService._alert.showToast(error?.error || "Error", "error");
            });
        });
    }, [dispatch]);

    const FacilityListColumn: TableColumnsType = useMemo(() => {
        return [
            {
                title: "Facility Name",
                key: 'name',
                dataIndex: 'name',
                width: 250,
                sorter: (a: any, b: any) => a?.name?.localeCompare(b?.name || '') || 0,
                render: (item: any) => {
                    return <div className={'facility-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'facility-name-tower-wrapper'}>
                            {
                                (item)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div style={{cursor: "pointer"}}
                                             className={"ellipses-for-table-data property-name"}>
                                            {item}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="facility-name">
                                        {item || 'N/A'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: "Agency Count",
                key: 'agencies_count',
                dataIndex: 'agencies_count',
                width: 155,
                sorter: (a: any, b: any) => (a?.agencies_count || 0) - (b?.agencies_count || 0),
                render: (item: any) => {
                    return <>{item || 0}</>;
                }
            },
            {
                title: "Onboarded On",
                key: 'created_at',
                dataIndex: 'created_at',
                width: 150,
                align: 'left',
                sorter: (a: any, b: any) =>
                    new Date(a?.created_at).getTime() - new Date(b?.created_at).getTime(),
                render: (item: any) => {
                    if (item) {
                        return <>{CommonService.convertDateFormat2(item, "MM-DD-YYYY")}</>;
                    } else {
                        return <>N/A</>;
                    }
                }
            },

            {
                title: "Admin Name",
                key: 'admin_details',
                dataIndex: 'admin_details',
                width: 250,
                sorter: (a: any, b: any) =>
                    `${a?.admin_details?.first_name || ''} ${a?.admin_details?.last_name || ''}`.localeCompare(
                        `${b?.admin_details?.first_name || ''} ${b?.admin_details?.last_name || ''}` || ''
                    ),
                render: (item: any) => {
                    return <div className={'facility-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        {Object.keys(item)?.length > 0 ?
                            <div className={'facility-name-tower-wrapper'}>
                                {
                                    (item?.first_name + " " + item?.last_name)?.length > 20 ?
                                        <ToolTipComponent
                                            tooltip={item?.first_name + " " + item?.last_name}
                                            showArrow={true}
                                            position={"top"}
                                        >
                                            <div style={{cursor: "pointer"}}
                                                 className={"ellipses-for-table-data property-name"}>
                                                {item?.first_name + " " + item?.last_name}
                                            </div>
                                        </ToolTipComponent> :
                                        <div className="facility-name">
                                            {item?.first_name + " " + item?.last_name || 'N/A'}
                                        </div>
                                }
                            </div> : <div>N/A</div>}
                    </div>;
                }
            },
            {
                title: "Admin Contact No",
                key: 'admin_details',
                dataIndex: 'admin_details',
                width: 180,
                // sorter: (a: any, b: any) =>
                //     (a?.admin_details?.contact_number || '').localeCompare(b?.admin_details?.contact_number || ''),
                render: (item: any) => {
                    return <>
                        {CommonService.formatPhoneNumber(item?.contact_number?.replace(/^\+1/, '')) || 'N/A'}
                    </>;
                }
            },
            {
                title: 'Active/Inactive',
                width: 140,
                render: (item: any) => {
                    return <div className={'display-center'}>
                        <CustomToggleComponent defaultOn={item?.is_active} toggleHandler={() => {
                            onFacilityActivateDeactivate(item);
                        }}/>
                    </div>;
                }
            },
            {
                title: "View Details",
                align: 'center',
                width: 130,
                render: (item: any) => {
                    if (item?._id) {
                        return <LinkComponent route={CommonService._routeConfig.FacilityDetailsRoute(item?._id)}>
                            <ImageConfig.ArrowRightOutlinedIcon color={"#4E535F"}/>
                        </LinkComponent>;
                    }
                }
            }
        ];
    }, [onFacilityActivateDeactivate]);


    return (
        <>
            <div className={'facility-list-component header-screen mrg-top-25 mrg-left-25 mrg-right-30'}>
                <div className="list-screen-header-wrapper">
                    <div className="list-screen-header">
                        {/*<div className={'mrg-bottom-5 home-text'}>Home</div>*/}
                        <div className="list-screen-header-title-and-count d-flex">
                            <div className={'mrg-right-10'}>
                                <ImageConfig.FacilityModuleHeaderIcon/>
                            </div>
                            <div className="list-screen-header-title">Facility</div>
                        </div>
                    </div>
                    <div className="list-options">
                        <div className={"mrg-top-20"}>
                            <SearchComponent
                                size={'medium'}
                                placeholder="Search Facility Name"
                                value={filterData.search}
                                onSearchChange={(value) => {
                                    setFilterData({...filterData, search: value, page: 1});
                                }}
                            />
                        </div>
                        <div>
                            <ButtonComponent color={'download-button'} variant={"contained"} isLoading={isDownloading}
                                             onClick={handleFacilityListDownload}>
                                Download CSV
                            </ButtonComponent>
                        </div>
                        {currentUser?.role !== 'super_admin' &&
                            <LinkComponent route={CommonService._routeConfig.AddFacilityRoute()}>
                                <ButtonComponent color={'primary'} style={{color: '#202937'}}
                                                 prefixIcon={<ImageConfig.AddOutlinedIcon/>}>
                                    Add New Facility
                                </ButtonComponent>
                            </LinkComponent>
                        }
                    </div>
                </div>
            </div>
            <div className={'facility-table-component mrg-left-25 mrg-right-30'}>
                {/*<div className="list-screen-content">*/}
                {/*    <TableWrapperComponent url={APIConfig.FACILITY_LIST.URL}*/}
                {/*                           method={APIConfig.FACILITY_LIST.METHOD}*/}
                {/*                           isPaginated={true}*/}
                {/*                           extraPayload={filterData}*/}
                {/*                           noDataText={filterData.search ? 'Oops! It seems like there are no Facilities Available for the name you have searched.' : 'No Facilities Data Found.'}*/}
                {/*                           columns={FacilityListColumn}*/}
                {/*    />*/}
                {/*</div>*/}
                <AntTableComponent
                    url={APIConfig.FACILITY_LIST.URL}
                    method={APIConfig.FACILITY_LIST.METHOD}
                    extraPayload={filterData}
                    isRowSelection={false}
                    columns={FacilityListColumn}
                    noDataText={filterData.search ? 'Oops! It seems like there are no Facilities Available for the name you have searched.' : 'No Facilities Data Found.'}
                />
            </div>
        </>
    );

};

export default FacilityListComponent;
