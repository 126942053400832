import * as Yup from "yup";

export const HcpValidationSchema = Yup.object().shape({
    contact_number: Yup.string().min(14, "Phone number must be 10 digits").required("Phone number is required"),
    staff_type: Yup.string().required('Staff Type is required'),
    email: Yup.string().email('Invalid Email address').required('Email Address is required'),
    about: Yup.string().max(1000, "About should be maximum 1000 characters"),
    hcp_uniqueId:Yup.string().required('Staff ID is required').min(2,"Minimum 2 characters are required"),
    first_name: Yup.string().required('First Name is required').min(2, "First Name should be minimum 2 characters").max(50, "First Name should be maximum 50 characters"),
    last_name: Yup.string().required('Last Name is required').min(2, "Last Name should be minimum 2 characters").max(50, "Last Name should be maximum 50 characters"),
    timezone: Yup.string().required('Timezone is required'),
    // gender: Yup.string().required('Gender is required'),
    hcp_type: Yup.string().required('Internal Staff Types Selection is required'),
    address: Yup.object().shape({
        street: Yup.string().required('Address Line is required').min(2, 'Address Line should be at least 2 characters').max(100, 'Address Line should be maximum 100 characters'),
        city: Yup.string().required('City is required').min(2, 'City should be at least 2 characters').max(30, 'City should be maximum 30 characters'),
        state: Yup.string().required('State is required').min(2, 'State should be at least 2 characters').max(30, 'State should be maximum 30 characters'),
        // region: Yup.string().required('Region is required').min(2, 'Region should be at least 2 characters'),
        country: Yup.string().required('Country is required').min(2, 'Country should be at least 2 characters').max(30, 'Country should be maximum 30 characters'),
        zip_code: Yup.string().required('ZIP Code is required').min(5, 'ZIP Code should be at least 5 characters').max(6, 'ZIP Code should be maximum 6 characters')
    }),
});