import React from "react";
import {Outlet} from "react-router-dom";
import {ImageConfig, Misc} from "../../constants";
import "./AuthLayout.scss";
import AppVersionComponent from "../../shared/components/app-version/appVersionComponent";

export interface AuthLayoutProps {

}

const AuthLayout = (props: React.PropsWithChildren<AuthLayoutProps>) => {

    return (
        <div className="auth-layout">
            <div className="mask-icon-container">
                <ImageConfig.MaskIcon/>
            </div>
            <div className={'branding-component'}>
                <div className="logo-wrapper mrg-left-30">
                    <img height={120} width={120} src={ImageConfig.Logo} alt={Misc.APP_NAME + 'Logo'}/>

                </div>
            </div>
            <div className="auth-wrapper">
                <div className="auth-form">
                    <Outlet/>
                </div>
            </div>
            <AppVersionComponent/>
        </div>
    );
};


export default AuthLayout;
