import "./InternalHcpManagementList.scss";
import SearchComponent from "../../../shared/components/search/SearchComponent";
import LinkComponent from "../../../shared/components/link/LinkComponent";
import {CommonService} from "../../../shared/services";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import {APIConfig, ImageConfig, Misc} from "../../../constants";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {setCurrentNavParams} from "../../../store/actions/navigation.action";
import {useDispatch, useSelector} from "react-redux";
import {IRootReducerState} from "../../../store/reducers";
import FormControl from "@mui/material/FormControl";
import {IAPIResponseType} from "../../../shared/models/api.model";
import CustomToggleComponent
    from "../../../shared/components/custom-toogle-using-button-component/CustomToggleComponent";
import SearchCheckboxComponent from "../../../shared/components/search-checkbox/SearchCheckboxComponent";
import {TableColumnsType} from "antd";
import AntTableComponent from "../../../shared/components/antd-table-component/AntTableComponent";
import ToolTipComponent from "../../../shared/components/tool-tip/ToolTipComponent";

interface HcpManagementListComponentProps {

}

const InternalHcpManagementListComponent = (props: HcpManagementListComponentProps) => {
    const dispatch = useDispatch();
    const [filterData, setFilterData] = useState<any>({
        search: '',
        is_internal_hcp: true,
        is_float: true,
    });
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const {HcpTypesList} = useSelector((state: IRootReducerState) => state.meta);
    const {currentUser} = useSelector((state: IRootReducerState) => state.account);
    const [selectedHcpTypeData, setSelectedHcpTypeData] = React.useState<Array<string>>([]);

    const onHCPActivateDeactivate = useCallback((item: any) => {
        CommonService.onConfirm(
            {
                confirmationTitle: `${item?.is_active ? 'Deactivate' : 'Activate'} Internal Staff`,
                image: item?.is_active ? ImageConfig.UserDeactivateIcon : ImageConfig.UserActivateIcon,
                confirmationDescription: `Are you sure you want to ${item?.is_active ? 'deactivate' : 'activate'}  the Internal Staff?`,
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                activationType: item?.is_active,
                yes: {
                    color: "error",
                    text: `Yes, ${item?.is_active ? 'Deactivate' : 'Activate'}`,
                    variant: "contained",
                }
            }
        ).then((result: any) => {
            const payload: any = {};
            payload.is_active = !item?.is_active;
            CommonService._hcp.ActivateDeactivateHCP(item?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                    setFilterData({...filterData});
                }).catch((error: any) => {
                CommonService._alert.showToast(error?.error || "Error", "error");
            });
        });
    }, [filterData]);

    const hcpListColumn:TableColumnsType=useMemo(()=>{
        return [
            {
                title:"Facility Name",
                key: 'facility_details',
                dataIndex: 'facility_details',
                width: 250,
                sorter: (a, b) => a.facility_details.name.localeCompare(b.facility_details.name),
                render: (item: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'hcp-name-tower-wrapper'}>
                            {
                                (item?.name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="facilty-name">
                                        {item?.name || 'N/A'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title:"Staff Name",
                width: 200,
                sorter: (a, b) =>
                    (a.first_name + " " + a.last_name).localeCompare(b.first_name + " " + b.last_name),
                render: (item: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'hcp-name-tower-wrapper'}>
                            {
                                (item?.first_name + " " + item?.last_name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.first_name + " " + item?.last_name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.first_name + " " + item?.last_name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="hcp-name">
                                        {item?.first_name + " " + item?.last_name || 'N/A'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title:"Staff ID",
                key: 'hcp_uniqueId',
                dataIndex: 'hcp_uniqueId',
                width: 200,
                sorter: (a, b) => a.hcp_uniqueId?.localeCompare(b.hcp_uniqueId),
                render: (item: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        <div className={'hcp-name-tower-wrapper'}>
                            {
                                (item)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="hcp-name">
                                        {item || 'N/A'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title:"Email ID",
                key: 'email',
                dataIndex: 'email',
                width: 250,
                sorter: (a, b) => a.email.localeCompare(b.email),
                render: (item: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        <div className={'hcp-name-tower-wrapper'}>
                            {
                                (item)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="facility-name">
                                        {item || 'N/A'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title:"Onboarded On",
                key: 'created_at',
                dataIndex: 'created_at',
                width: 150,
                align: 'left',
                sorter: (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
                render: (item: any) => {
                    if (item) {
                        return <>{CommonService.convertDateFormat2(item, "MM-DD-YYYY")}</>;
                    } else {
                        return <>N/A</>;
                    }
                }
            },
            {
                title:"Staff Type",
                key: 'hcp_type_details',
                dataIndex: 'hcp_type_details',
                width:250,
                sorter: (a, b) => a.hcp_type_details?.title?.localeCompare(b.hcp_type_details?.title),
                render: (item: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        <div className={'hcp-name-tower-wrapper'}>
                            {
                                (item?.title)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.title}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.title}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="hcp-name">
                                        {item?.title || 'N/A'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title:"Location",
                key: 'address',
                dataIndex: 'address',
                width: 150,
                sorter: (a, b) => (a.address.city || "").localeCompare(b.address.city || ""),
                render: (item: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        <div className={'hcp-name-tower-wrapper'}>
                            {
                                (item?.city)?.length > 15 ?
                                    <ToolTipComponent
                                        tooltip={item?.city}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.city}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="hcp-name">
                                        {item?.city || 'N/A'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title:"Phone Number",
                key: 'contact_number',
                dataIndex: 'contact_number',
                width: 150,
                // sorter: (a, b) => a.contact_number.localeCompare(b.contact_number),
                render: (item: any) => {
                    return <>
                        {CommonService.formatPhoneNumber(item?.replace(/^\+1/, '')) || "N/A"}
                    </>;
                }
            },
            {
                title:"Type",
                key: 'is_float',
                dataIndex: 'is_float',
                width:100,
                render: (item: any) => {
                    return <>
                        {item ? <div>
                            <div style={{
                                color: "#37B96B"
                            }}>Floating
                            </div>
                        </div> : <>
                            <div style={{
                                color: "#518FC9"
                            }}>Internal
                            </div>
                        </>}
                    </>;
                }
            },
            {
                title: 'Active/Inactive',
                width: 140,
                render: (item: any) => {
                    return <div className={'display-center'}>
                        <CustomToggleComponent defaultOn={item?.is_active} toggleHandler={() => {
                            onHCPActivateDeactivate(item);
                        }}/>
                    </div>;
                }
            },
            {
                title: "View Details",
                align: 'center',
                width: 130,
                render: (item: any) => {
                    if (item?._id) {
                        return <div>
                            <LinkComponent route={CommonService._routeConfig.internalHcpViewDetails(item?._id)}>
                                <ImageConfig.ArrowRightOutlinedIcon color={"#4E535F"}/>
                            </LinkComponent>
                        </div>;
                    }
                }
            }
        ]
    },[onHCPActivateDeactivate])


    useEffect(() => {
        dispatch(setCurrentNavParams('HCP Management') as any);
    }, [dispatch]);

    const handleHcpTypeSelection = (selectedOptions: any) => {
        setSelectedHcpTypeData(selectedOptions);
    };

    useEffect(() => {
        const hcpTypeIdsArray = selectedHcpTypeData.map((item: any) => item?.code);
        if (selectedHcpTypeData?.length > 0) {
            setFilterData({
                ...filterData,
                hcp_types: hcpTypeIdsArray,
            });
        } else {
            delete filterData?.hcp_types;
            setFilterData({
                ...filterData
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedHcpTypeData]);


    const handleDownloadHCPs = useCallback(() => {
        setIsDownloading(true);
        const payload: any = {};
        const {status} = filterData;
        payload.status = [status];
        let apiCall: any = {};
        apiCall = CommonService._hcp.downloadHCPDataCSVAPICall(filterData);

        apiCall.then((response: IAPIResponseType<any>) => {
            if (response?.data) {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                const link = document.createElement("a");
                link?.setAttribute("href", response?.data);
                document.body.appendChild(link);
                link.click();
                setIsDownloading(false);
            } else {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "error");
                setIsDownloading(false);
            }

        })
            .catch((error: any) => {
                setIsDownloading(false);
            }).finally(() => {

        });
    }, [filterData]);


    return (
        <>
            <div className={'facility-list-component header-screen mrg-top-25 mrg-left-25 mrg-right-30'}>
                <div className="list-screen-header-wrapper">
                    <div className="list-screen-header">
                        {/*<div className={'mrg-bottom-5 home-text'}>Home</div>*/}
                        <div className="list-screen-header-title-and-count d-flex">
                            <div className={'mrg-right-10'}>
                                <ImageConfig.InternalHcpModuleHeaderIcon/>
                            </div>
                            <div className="list-screen-header-title">Internal Staff</div>
                        </div>
                    </div>
                    <div className="list-options">
                        <div className={"mrg-top-20"}>
                            <SearchComponent
                                size={'medium'}
                                placeholder="Search Internal Staff Name"
                                value={filterData.search}
                                onSearchChange={(value) => {
                                    setFilterData({...filterData, search: value, page: 1});
                                }}
                            />
                        </div>
                        <div>
                            <FormControl sx={{m: 1, width: 280}}>
                                <SearchCheckboxComponent
                                    options={HcpTypesList}
                                    placeholder={"Select Staff Type"}
                                    selectedOptions={selectedHcpTypeData}
                                    onChange={handleHcpTypeSelection}
                                    noDataText={"No Staff Type"}
                                />
                            </FormControl>
                        </div>
                        <div>
                            <ButtonComponent color={'download-button'} variant={"contained"} isLoading={isDownloading}
                                             onClick={handleDownloadHCPs}>
                                Download CSV
                            </ButtonComponent>
                        </div>
                        {currentUser?.role !== 'super_admin' &&
                            <LinkComponent route={CommonService._routeConfig.AddInternalHcpRoute()}>
                                <ButtonComponent color={'primary'} prefixIcon={<ImageConfig.AddOutlinedIcon/>}>
                                    Add Internal Staff
                                </ButtonComponent>
                            </LinkComponent>
                        }
                    </div>
                </div>
            </div>
            {/*<div className={'facility-list-component list-screen mrg-top-10 mrg-left-25 mrg-right-30'}>*/}
            {/*    <div className="list-screen-content">*/}
            {/*        <TableWrapperComponent url={APIConfig.HCP_MANAGEMENT_LIST.URL}*/}
            {/*                               method={APIConfig.HCP_MANAGEMENT_LIST.METHOD}*/}
            {/*                               isPaginated={true}*/}
            {/*                               extraPayload={filterData}*/}
            {/*                               noDataText={filterData.search ? 'Oops! It seems like there are no Staff available for the name you have searched.' :*/}
            {/*                                   filterData.hcp_types ? "Oops! It seems like there are no Staff available for the selected staff types.":*/}
            {/*                                   'No Staffs Data Found.'}*/}
            {/*                               columns={HCPListColumn}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className={'facility-list-component mrg-left-25 mrg-right-30'}>
                <AntTableComponent
                    url={APIConfig.HCP_MANAGEMENT_LIST.URL}
                    method={APIConfig.HCP_MANAGEMENT_LIST.METHOD}
                    extraPayload={filterData}
                    isRowSelection={false}
                    columns={hcpListColumn}
                    noDataText={filterData.search ? 'Oops! It seems like there are no Staff available for the name you have searched.' :
                        filterData.hcp_types ? "Oops! It seems like there are no Staff available for the selected staff types.":
                            'No Staffs Data Found.'}
                    // dataSource={}
                />
            </div>
        </>
    );
};

export default InternalHcpManagementListComponent;
