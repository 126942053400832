import "./EditAdminDetailsComponent.scss";
import React, {useCallback, useEffect, useState} from "react";
import {ImageConfig, Misc} from "../../../constants";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import FormikInputComponent from "../../../shared/components/form-controls/formik-input/FormikInputComponent";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import {CommonService} from "../../../shared/services";
import {IAPIResponseType} from "../../../shared/models/api.model";
import * as Yup from "yup";
import LabelComponent from "../../../shared/components/label/LabelComponent";
// import PhoneInputComponent from "../../../shared/components/phoneInput/PhoneInputComponent";
import FormikCheckBoxComponent from "../../../shared/components/form-controls/formik-check-box/FormikCheckBoxComponent";
import FormikPhoneInputComponent
    from "../../../shared/components/form-controls/formik-phone-input/FormikPhoneInputComponent";
import FormikSelectDropdownComponent
    from "../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent";
import {designationNames} from "../../../data";

interface EditAdminComponentProps {
    facilityDetails: any;
    closeEditDrawer: any;
    getFacilityDetails: any;
}

const EditFacilityValidationSchema = Yup.object().shape({
        first_name: Yup.string().required('first name is required').min(2, 'First Name should be at least 2 characters')
            .max(50, 'First Name should not exceed 50 characters'),
        last_name: Yup.string().required('last name is required').min(2, 'Last Name should be at least 2 characters')
            .max(50, 'Last Name should not exceed 50 characters'),
        contact_number: Yup.string().min(14, "Phone number must be 10 digits").required("Required"),
        // role:Yup.string().required('Role is required'),
        email: Yup.string().email('Invalid admin email address').required('Admin email is required'),
    }
);

interface EditAdminFormInitialValuesFormSchema {
    first_name: string;
    last_name: string;
    contact_number: string;
    email: string;
    notification_preferences: {
        isSmsRequired: any;
        isEmailRequired: any;
        isPush_notificationRequired: any;
    };
}

const EditAdminFormInitialValues: EditAdminFormInitialValuesFormSchema = {
    first_name: "",
    last_name: "",
    contact_number: "",
    email: "",
    notification_preferences: {
        isSmsRequired: true,
        isEmailRequired: true,
        isPush_notificationRequired: true,
    }
};


const EditAdminDetailsComponent = (props: EditAdminComponentProps) => {
    const {facilityDetails, closeEditDrawer, getFacilityDetails} = props;
    console.log(facilityDetails,"facility")
    const [initialValues, setInitialValues] = useState<EditAdminFormInitialValuesFormSchema>(EditAdminFormInitialValues);

    useEffect(() => {
        const basicDetails = {
            first_name: facilityDetails?.first_name,
            last_name: facilityDetails?.last_name,
            contact_number:facilityDetails?.contact_number?.replace(/^\+1/, '') || '',
            email: facilityDetails?.email,
            role:facilityDetails?.role,
            notification_preferences: {
                isSmsRequired: facilityDetails?.notification_preferences?.isSmsRequired,
                isEmailRequired: facilityDetails?.notification_preferences?.isEmailRequired,
                isPush_notificationRequired: facilityDetails?.notification_preferences?.isPush_notificationRequired,
            }
        };
        setInitialValues(basicDetails);
    }, [facilityDetails]);

    const onSubmitAdminDetails = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        const payload = {
            ...values,
            contact_number:"+1"+values?.contact_number.replace(/\D/g, '')
        };
        let apiCall: any = {};
        if(facilityDetails?._id){
            apiCall = CommonService._facility.EditFacilityAdminAPICall(facilityDetails?._id, payload);
        }else{
            let updatedPayload={
                admin_contact_number:payload.contact_number,
                admin_email:payload.email,
                role:payload?.role,
                admin_first_name:payload.first_name,
                admin_last_name:payload.last_name,
                facility_id:facilityDetails?._id,
                notification_preferences:payload.notification_preferences

            };
            apiCall = CommonService._facility.AddFacilityAdminApiCall(updatedPayload);
        }
        apiCall.then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            closeEditDrawer();
            getFacilityDetails();
        })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
            }).finally(() => {
            setSubmitting(false);
        });
    }, [closeEditDrawer, getFacilityDetails, facilityDetails]);

    return (
        <>
            <div className={'edit-admin-component'}>
                <div className={'edit-drawer-header'}>
                    <div className={'form-header-text'}>Edit Admin Details</div>
                    <div className={'icon-button'} onClick={closeEditDrawer}><ImageConfig.CrossIcon/></div>
                </div>
                <div className={'formik-container'}>
                    <Formik
                        validationSchema={EditFacilityValidationSchema}
                        initialValues={initialValues}
                        validateOnChange={false}
                        validateOnBlur={true}
                        enableReinitialize={true}
                        validateOnMount={true}
                        onSubmit={onSubmitAdminDetails}
                    >
                        {({values, errors, validateForm, isValid, setFieldValue, isSubmitting}) => {
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            useEffect(() => {
                                validateForm();
                            }, [validateForm, values]);
                            return (
                                <Form className="t-form scroll-content form-holder" noValidate={true}>
                                    {/*<FormDebuggerComponent values={values} errors={errors} showDebugger={true}/>*/}
                                    <div className={'add-form-holder'}>
                                        <div className={'add-form-input-container'}>
                                            <div className="edit-component-content">
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-right-25 half-width'}>
                                                        <Field name={'first_name'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'First Name'}
                                                                        placeholder={'Enter First Name'}
                                                                        type={"text"}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className={'half-width'}>
                                                        <Field name={'last_name'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'Last Name'}
                                                                        placeholder={'Enter Last Name'}
                                                                        type={"text"}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-right-25 half-width'}>
                                                        <LabelComponent title={'Phone Number*'}/>
                                                        <Field name={'contact_number'}>
                                                            {/*{(field: FieldProps) => {*/}
                                                            {/*    return <PhoneInputComponent field={field}*/}
                                                            {/*                                placeholder={"Enter Phone number"}/>;*/}
                                                            {/*}}*/}
                                                            {(field:FieldProps)=>(
                                                                <FormikPhoneInputComponent
                                                                    placeholder={"Enter Phone number"}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                    required={true}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className={'half-width'}>
                                                        <Field name={'email'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'Email Address'}
                                                                        placeholder={'Enter Email Address'}
                                                                        type={"text"}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                        required={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className={'full-width'}>
                                                    <Field name={'role'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikSelectDropdownComponent
                                                                    disabled={true}
                                                                    options={designationNames}
                                                                    displayWith={(option: any) => option.title}
                                                                    valueExtractor={(option: any) => option.value}
                                                                    label={'Role'}
                                                                    placeholder={'Select Role'}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                    searchable={true}
                                                                    noDataMessage={"No role available"}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                                <div className={'form-top-header-text'}>Notification Preferences</div>
                                                <div className="ts-row">
                                                    <div className="ts-col-4">
                                                        <Field name={'notification_preferences.isSmsRequired'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikCheckBoxComponent
                                                                        formikField={field}
                                                                        label={"SMS"}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className="ts-col-4">
                                                        <Field name={'notification_preferences.isEmailRequired'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikCheckBoxComponent
                                                                        formikField={field}
                                                                        label={"Email"}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className="ts-col-4">
                                                        <Field
                                                            name={'notification_preferences.isPush_notificationRequired'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikCheckBoxComponent
                                                                        formikField={field}
                                                                        label={"In App"}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="drawer-edit-save-button d-flex">
                                        <ButtonComponent
                                            disabled={isSubmitting || !isValid}
                                            isLoading={isSubmitting}
                                            color={'secondary'}
                                            type={'submit'}
                                        >
                                            Save Details
                                        </ButtonComponent>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </>
    );
};
export default EditAdminDetailsComponent;

