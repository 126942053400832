import "./OpenShiftDetailsComponent.scss";
import DataLabelValueComponent from "../../../../shared/components/data-label-value/DataLabelValueComponent";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {CommonService} from "../../../../shared/services";
import HorizontalLineComponent
    from "../../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import TabsWrapperComponent, {
    TabComponent,
    TabContentComponent,
    TabsComponent
} from "../../../../shared/components/tabs/TabsComponent";
import ButtonComponent from "../../../../shared/components/button/ButtonComponent";
import {APIConfig, ImageConfig, Misc} from "../../../../constants";
import LoaderComponent from "../../../../shared/components/loader/LoaderComponent";
// import TableWrapperComponent from "../../../../shared/components/table-wrapper/TableWrapperComponent";
// import {ITableColumn} from "../../../../shared/models/table.model";
import ToolTipComponent from "../../../../shared/components/tool-tip/ToolTipComponent";
import {useNavigate, useParams} from "react-router-dom";
import {IAPIResponseType} from "../../../../shared/models/api.model";
import TextAreaComponent from "../../../../shared/components/form-controls/text-area/TextAreaComponent";
import DialogComponent from "../../../../shared/components/dialog-component/DialogComponent";
import AssignHcpToShiftComponent from "./assign-hcp/AssignHcpToShiftComponent";
import DrawerComponent from "../../../../shared/components/drawer/DrawerComponent";
import LinkComponent from "../../../../shared/components/link/LinkComponent";
import HCPDetailsComponent from "./HCPDetailsComponent";
import DetailsHeaderComponent from "../../../../shared/components/details-header-component/DetailsHeaderComponent";
import {SHIFT_REQUIREMENT_LIST} from "../../../../constants/RoutesConfig";
import SelectDropdownComponent
    from "../../../../shared/components/form-controls/select-dropdown/SelectDropdownComponent";
import {useDispatch, useSelector} from "react-redux";
import {IRootReducerState} from "../../../../store/reducers";
import {getFacilityAgencyListLite} from "../../../../store/actions/static-data.action";
import {shiftTypeLayoutRepresentation} from "../../../../data";
import {Tooltip} from "@mui/material";
import ApproveApplicationComponent from "../../application-approve-component/ApproveApplicationComponent";
import ContractTravelApproveApplicationComponent
    from "../../contract-travel-approve-component/ContarctTravelApproveComponent";
import {TableColumnsType} from "antd";
import AntTableComponent from "../../../../shared/components/antd-table-component/AntTableComponent";

interface OpenShiftDetailsComponentProps {
    shiftRequirementDetails: any;
    isShiftDetailsLoading: any;
    isShiftDetailsLoaded: any;
    recall: any;
}

const OpenShiftDetailsComponent = (props: OpenShiftDetailsComponentProps) => {
    const dispatch = useDispatch();
    const {shiftRequirementDetails, isShiftDetailsLoading, isShiftDetailsLoaded, recall} = props;
    const [activeTab, setActiveTab] = useState<any>("pending");
    const [cancelConformation, setCancelConformation] = useState<boolean>(false);
    const [loadingCancellationReason, setLoadingCancellationReason] = useState<boolean>(false);
    const [filterData, setFilterData] = useState<any>({
        search: '',
        status: "pending",
        // sort:{},
    });
    const [selectedAgencyForHcp, setSelectedAgency] = useState<any>({
        selectedAgency: null,
        // sort:{},
    });
    const {requirementId} = useParams();
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [shiftCancellationReason, setShiftCancellationReason] = useState<any>(null);
    const [selectedApplicationId, setSelectedApplicationId] = useState<string>("");
    const [isRejectButtonLoading, setIsRejectButtonLoading] = useState<boolean>(false);
    const [loadingStates, setLoadingStates] = useState<any>([]);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [openHcpDetailsDrawer, setOpenHcpDetailsDrawer] = useState<boolean>(false);
    const [hcpListRequirementBased, setHcpListRequirementBased] = useState<any>(null);
    const {facilityAgencyList} = useSelector((state: IRootReducerState) => state.staticData);
    const [cancellationReason, setCancellationReason] = useState<any>(null);
    const [hcpDetails, setHcpDetails] = useState<any>(null);
    const navigate = useNavigate();
    const [isApproveBoxOpen, setIsApproveBoxOpen] = useState<boolean>(false);
    const [isContractApprovalBoxOpen, setIsContractApprovalBoxOpen] = useState<boolean>(false);
    const [applicationShiftDetails, setApplicationShiftDetails] = useState<any>([]);

    const approveHcpApplication = useCallback((application_id: any, index: any) => {
        const newLoadingStates = [...loadingStates];
        newLoadingStates[index] = true;
        setLoadingStates(newLoadingStates);
        const payload = {};
        CommonService._shift_management.approveShiftRequirementApplicationAPICall(application_id, payload)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                const newLoadingStates = [...loadingStates];
                newLoadingStates[index] = false;
                setLoadingStates(newLoadingStates);
                setActiveTab("approved");
                setFilterData({...filterData, status: "approved"});
                recall(true)
            }).catch((error: any) => {
            const newLoadingStates = [...loadingStates];
            newLoadingStates[index] = false;
            setLoadingStates(newLoadingStates);
            CommonService._alert.showToast(error?.error, "error");
        });
    }, [filterData, loadingStates, recall]);


    const rejectHcpApplication = useCallback(() => {
        setIsRejectButtonLoading(true);
        const payload: any = {};
        let apiCall: any = {};
        payload.rejected_reason = shiftCancellationReason;
        apiCall = CommonService._shift_management.rejectShiftRequirementApplicationAPICall(selectedApplicationId, payload);
        apiCall.then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            CommonService._staticData.openDialogPopup(setOpenDialog, false);
            setIsRejectButtonLoading(false);
            setActiveTab("rejected");
            setFilterData({...filterData, status: "rejected"});
            recall(true)
        })
            .catch((error: any) => {
                CommonService._alert.showToast(error?.error, "error");
                setIsRejectButtonLoading(false);
            }).finally(() => {

        });
    }, [shiftCancellationReason, selectedApplicationId, filterData, recall]);

    useEffect(() => {
        if (isShiftDetailsLoaded) {
            dispatch(getFacilityAgencyListLite(shiftRequirementDetails?.facility_details?.data?._id));
        }
    }, [dispatch, isShiftDetailsLoaded, shiftRequirementDetails?.facility_details?.data?._id]);


    const ShiftsListColumn: TableColumnsType = useMemo(() => {
        return [
            {
                title: 'Staff Name',
                key: 'hcp_details',
                dataIndex: 'hcp_details',
                width: 200,
                align: 'left',
                render: (item: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'hcp-name-tower-wrapper'}>
                            {
                                (item?.first_name + item?.last_name)?.length > 15 ?
                                    <ToolTipComponent
                                        tooltip={item?.first_name + item?.last_name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.first_name + " " + item?.last_name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="hcp-name">
                                        {item?.first_name + " " + item?.last_name || "N/A"}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: 'Agency Name',
                width: 250,
                align: 'left',
                render: (item: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'hcp-name-tower-wrapper'}>
                            {!item?.is_internal_hcp &&
                            (item?.agency_details?.name)?.length > 20 ?
                                <ToolTipComponent
                                    tooltip={item?.agency_details?.name}
                                    showArrow={true}
                                    position={"top"}
                                >
                                    <div className={"ellipses-for-table-data property-name"}>
                                        {item?.agency_details?.name}
                                    </div>
                                </ToolTipComponent> :
                                <div className="hcp-name">
                                    {item?.agency_details?.name || 'N/A'}
                                </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title:"Differential $",
                key: 'differential',
                dataIndex: 'differential',
                width: 120,
                render: (item: any) => {
                    if (item) {
                        return (
                            <>
                                <div onClick={() => {
                                }} style={{color: "#E08519"}}>${item}</div>
                            </>
                        );
                    } else {
                        return <>N/A</>;
                    }
                }
            },
            {
                title: '',
                width: 150,
                fixed: "right",
                render: (_:any,item: any, index: any) => {
                    return (
                        <div className={'display-center'}>
                            {(activeTab === "pending" || activeTab === "rejected") &&
                                <ButtonComponent isLoading={loadingStates[index]} onClick={() => {
                                    if (shiftRequirementDetails?.requirementType === "Home Health") {
                                        setApplicationShiftDetails(item)
                                        setIsApproveBoxOpen(true);
                                    } else if (shiftRequirementDetails?.requirementType === "Contract Travel") {
                                        setApplicationShiftDetails(item);
                                        setIsContractApprovalBoxOpen(true)
                                    } else {
                                        approveHcpApplication(item?._id, index);
                                    }
                                }} style={{
                                    backgroundColor: "rgba(13, 185, 216, 0.10)",
                                    color: "#0DB9D8",
                                    boxShadow: "none",
                                    border: "1px solid #0DB9D8"
                                }}>
                                    Approve
                                </ButtonComponent>}
                        </div>
                    );
                }
            },
            {
                title: '',
                width: 150,
                fixed: "right",
                render: (item: any) => {
                    return (
                        <div className={'display-center'}>
                            {(activeTab === "pending") &&
                                <ButtonComponent onClick={() => {
                                    setSelectedApplicationId(item?._id);
                                    CommonService._staticData.openDialogPopup(setOpenDialog, true);
                                }} style={{
                                    backgroundColor: "#FFF4F4",
                                    color: "#DB5656",
                                    boxShadow: "none",
                                    border: "1px solid #DB5656"
                                }}>
                                    Reject
                                </ButtonComponent>}
                        </div>
                    );
                }
            },

            {
                title: "",
                align: 'center',
                width: 100,
                fixed: 'right',
                render: (item: any) => {
                    if (item?._id) {
                        return <div>
                            <LinkComponent onClick={() => {
                                setHcpDetails(item);
                                CommonService._staticData.openDialogPopup(setOpenHcpDetailsDrawer, true);
                            }}>
                                <ImageConfig.ArrowRightOutlinedIcon/>
                            </LinkComponent>
                        </div>;
                    }
                }
            }
        ];
    }, [activeTab, approveHcpApplication, loadingStates, shiftRequirementDetails]);

    const handleSubmit = useCallback(() => {
        const payload: any = {
            is_active: true,

            //  agency_id: selectedAgencyForHcp?.selectedAgency
        };
        if (selectedAgencyForHcp?.selectedAgency) {
            payload.agency_id = selectedAgencyForHcp?.selectedAgency;
            payload.requirement_id = requirementId;
        } else {
            payload.facility_id = shiftRequirementDetails?.facility_details?.data?._id;
            payload.is_float = shiftRequirementDetails?.send_to?.is_float;
            payload.is_internal_hcp = shiftRequirementDetails?.send_to?.is_internal_hcp;
            payload.requirement_id = requirementId;
        }

        CommonService._hcp.HcpListRequirementBasedAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                setHcpListRequirementBased(response?.data);
            }).catch((error: any) => {
            setHcpListRequirementBased([]);
        }).finally(() => {
        });
    }, [requirementId, selectedAgencyForHcp?.selectedAgency, shiftRequirementDetails?.facility_details?.data?._id, shiftRequirementDetails?.send_to?.is_float, shiftRequirementDetails?.send_to?.is_internal_hcp]);

    useEffect(() => {
        if (isShiftDetailsLoaded) {
            if (selectedAgencyForHcp?.selectedAgency) {
                handleSubmit();
            } else if (shiftRequirementDetails?.facility_details?.data?._id) {
                handleSubmit();
            }
        }
    }, [handleSubmit, isShiftDetailsLoaded, selectedAgencyForHcp?.selectedAgency, shiftRequirementDetails?.facility_details?.data?._id]);


    const CancelShiftDetails = useCallback((requirementId: any, reason: any) => {
        setCancelConformation(true);
        const payload = {
            "reason": reason
        };
        setLoadingCancellationReason(true);
        CommonService._shift_management.cancelShiftDetails(requirementId, payload)
            .then((response: IAPIResponseType<any>) => {
                console.log(response);
                recall(true);
                setLoadingCancellationReason(false);
                CommonService._alert.showToast(response?.message, "success");
                setCancelConformation(false);
                setCancellationReason(null);

            }).catch((error: any) => {
            CommonService._alert.showToast(error?.message, "error");
        });
    }, [recall]);

    const modifiedData = shiftRequirementDetails?.agencies?.map((item: any) => ({
        code: item._id,
        title: item.name
    }));


    const address = [
        shiftRequirementDetails?.hospice_details?.apt,
        shiftRequirementDetails?.hospice_details?.street,
        shiftRequirementDetails?.hospice_details?.city,
        shiftRequirementDetails?.hospice_details?.state,
        shiftRequirementDetails?.hospice_details?.zipcode
    ].filter(Boolean).join(', ') || 'N/A';


    return (
        <>
            <DialogComponent cancel={() => {
                setIsApproveBoxOpen(false)
            }} open={isApproveBoxOpen} maxWidth={'xl'}>
                <ApproveApplicationComponent
                    setActiveTab={setActiveTab}
                    setOpenFilterData={setFilterData}
                    recall={recall}
                    openFilterData={filterData}
                    status={"open-details"}
                    setIsApproveBoxOpen={setIsApproveBoxOpen}
                    selectedApplicationForApproval={[applicationShiftDetails]}
                    visitPatientType={shiftRequirementDetails?.hospice_details?.visit_patient_time}
                />
            </DialogComponent>
            <DialogComponent cancel={() => {
                setIsContractApprovalBoxOpen(false)
            }} open={isContractApprovalBoxOpen} maxWidth={'xl'}>
                <ContractTravelApproveApplicationComponent
                    setActiveTab={setActiveTab}
                    setOpenFilterData={setFilterData}
                    recall={recall}
                    openFilterData={filterData}
                    status={"open-details"}
                    setIsContractApprovalBoxOpen={setIsContractApprovalBoxOpen}
                    selectedApplicationForApproval={[applicationShiftDetails]}/>
            </DialogComponent>
            <DrawerComponent onClose={() => {
                CommonService._staticData.openDialogPopup(setOpenDrawer, false);
            }} isOpen={openDrawer}>
                <AssignHcpToShiftComponent selectedAgencyForHcp={selectedAgencyForHcp}
                                           handleHcpRequiredSubmit={handleSubmit}
                                           hcpListRequirementBased={hcpListRequirementBased}
                                           filterData={filterData} setFilterData={setFilterData}
                                           setOpenDrawer={setOpenDrawer}
                                           shiftRequirementDetails={shiftRequirementDetails}
                                           recall={recall}
                />
            </DrawerComponent>
            {
                cancelConformation &&
                <DialogComponent cancel={() => {
                    setCancelConformation(false);
                }} open={cancelConformation}>
                    <div style={{minWidth: "450px"}}>
                        <div className={"d-flex"}
                             style={{
                                 justifyContent: "space-between",
                                 alignItems: "center",
                                 padding: "10px 15px"
                             }}>
                            <div className={'header-text-font'}>Cancel Shift</div>
                            <div className={'icon-button'} onClick={() => {
                                setCancelConformation(false);
                            }}>
                                <ImageConfig.CrossIcon/>
                            </div>
                        </div>
                        <HorizontalLineComponent/>
                        <div style={{padding: "10px 15px"}}>
                            <TextAreaComponent required={true}
                                               placeholder={"Type in your reason here..."}
                                               rows={6}
                                               fullWidth
                                               label={"Reason For Cancellation"}
                                               onChange={(e) => {
                                                   setCancellationReason(e);
                                               }}/>
                        </div>

                        <div className={"d-flex mrg-bottom-10"}
                             style={{
                                 justifyContent: "center",
                                 alignItems: "center"
                             }}>
                            <ButtonComponent disabled={!cancellationReason}
                                             isLoading={loadingCancellationReason}
                                             onClick={() => {
                                                 CancelShiftDetails(requirementId, cancellationReason);
                                             }} style={{
                                backgroundColor: "#FFF4F4",
                                color: "#DB5656",
                                boxShadow: "none",
                                border: "1px solid #DB5656",
                                marginLeft: "10px"
                            }}
                                             prefixIcon={
                                                 <ImageConfig.CancelIconRed/>}
                            >
                                Cancel Shift
                            </ButtonComponent>
                        </div>

                    </div>
                </DialogComponent>
            }
            <DialogComponent cancel={() => {
                CommonService._staticData.openDialogPopup(setOpenDialog, false);
            }} open={openDialog}>
                <div style={{minWidth: "450px"}}>
                    <div className={"d-flex"}
                         style={{
                             justifyContent: "space-between",
                             alignItems: "center",
                             padding: "10px 15px"
                         }}>
                        <div className={'header-text-font'}>Reject Staff Application</div>
                        <div className={'icon-button'} onClick={() => {
                            CommonService._staticData.openDialogPopup(setOpenDialog, false);
                            setShiftCancellationReason(null);
                        }}>
                            <ImageConfig.CrossIcon/>
                        </div>
                    </div>
                    <HorizontalLineComponent/>
                    <div style={{padding: "10px 15px"}}>
                        <TextAreaComponent required={true} placeholder={"Type in your reason here"} rows={6}
                                           fullWidth
                                           label={"Reason For Rejection"} onChange={(e) => {
                            setShiftCancellationReason(e);
                        }}/>
                    </div>
                    <div className={"d-flex mrg-bottom-10"}
                         style={{justifyContent: "center", alignItems: "center"}}>
                        <ButtonComponent disabled={!shiftCancellationReason}
                                         isLoading={isRejectButtonLoading} onClick={rejectHcpApplication}
                                         style={{
                                             backgroundColor: "#FFF4F4",
                                             color: "#DB5656",
                                             boxShadow: "none",
                                             border: "1px solid #DB5656",
                                             marginLeft: "10px"
                                         }}
                            //   disabled={selectedShifts.length === 0}
                        >
                            Reject Staff Application
                        </ButtonComponent>
                    </div>
                </div>
            </DialogComponent>
            <DrawerComponent onClose={() => {
                CommonService._staticData.openDialogPopup(setOpenHcpDetailsDrawer, false);
            }} isOpen={openHcpDetailsDrawer}>
                <HCPDetailsComponent setOpenDrawer={setOpenHcpDetailsDrawer} shiftHcpDetails={hcpDetails}/>
            </DrawerComponent>
            {
                isShiftDetailsLoading && <div className="h-v-center">
                    <LoaderComponent type={'spinner'}/>
                </div>
            }
            <div className={'details-component'}>
                <div className={'element-one'}>
                    {isShiftDetailsLoaded &&
                        <DetailsHeaderComponent navigate={navigate} details={shiftRequirementDetails}
                                                backAddress={SHIFT_REQUIREMENT_LIST}
                                                detailsModule={'Open Shift'}/>
                    }
                </div>
                <div className={'element-two'}>
                    {!isShiftDetailsLoading &&
                        <>
                            {isShiftDetailsLoaded && <>
                                <div className={'open-shift-view-details-component'}>
                                    <div className={"shift-details-wrapper"}>
                                        <div className={'d-flex justify-content-space-between'}>
                                            <div className={"shift-details-header mrg-bottom-15"}>Shift Requirement
                                                Details
                                            </div>
                                            <div
                                                className={`requirement-status requirement-${shiftRequirementDetails?.status}-status`}>
                                                {shiftRequirementDetails.status.split('_').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                            </div>
                                        </div>
                                        <div className={"shift-details-card-wrapper"}>
                                            <div
                                                className={'d-flex justify-content-space-between ts-align-items-center mrg-bottom-20'}>
                                                <div className={'header-text-font'}>
                                                    {shiftRequirementDetails?.requirementType === "Contract Travel" ? " Opportunity Details" : "Shift Details"}
                                                </div>
                                                <div className={'d-flex'}>
                                                    {
                                                        (shiftRequirementDetails?.status !== "cancelled" && shiftRequirementDetails?.status !== "filled") &&
                                                        <div>
                                                            <ButtonComponent
                                                                onClick={() => setCancelConformation(true)}
                                                                style={{
                                                                    backgroundColor: "#FFF0F0",
                                                                    color: "#E01E1E",
                                                                    boxShadow: "none",
                                                                    border: "1px solid #E01E1E"
                                                                }} className={'cancel-shift-button'}
                                                            >
                                                                Cancel Shift
                                                            </ButtonComponent>
                                                        </div>
                                                    }
                                                    <div
                                                        className={`requirement-holder-daily-monthly-view  d-flex align-items-center mrg-left-10`}>
                                                        {shiftRequirementDetails.shift_type}&nbsp;&nbsp;{shiftTypeLayoutRepresentation.find(shiftTypeObj => shiftTypeObj.code === shiftRequirementDetails.shift_type)?.icon}
                                                    </div>

                                                </div>
                                            </div>
                                            {(shiftRequirementDetails?.requirementType !== "Home Health") && <>
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-right-20 mrg-bottom-20'}>
                                                        <ImageConfig.FacilityIcon/>
                                                    </div>
                                                    <Tooltip title={shiftRequirementDetails?.facility_details?.data.name} placement={'top'}>
                                                        <div>{shiftRequirementDetails?.facility_details?.data.name || 'N/A'}</div>
                                                    </Tooltip>
                                                </div>
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-right-20 mrg-bottom-20'}>
                                                        <ImageConfig.ClockIconSmall/>
                                                    </div>
                                                    <div className={'form-minor-text'}>
                                                        {CommonService?.convertMinutesToTime(shiftRequirementDetails?.shift_timings?.start_time) + "-" + CommonService?.convertMinutesToTime(shiftRequirementDetails?.shift_timings?.end_time)}
                                                    </div>
                                                </div>
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-right-20 mrg-bottom-20'}>
                                                        <ImageConfig.CalenderIconSmall/>
                                                    </div>
                                                    <div className={'form-minor-text'}>
                                                        {CommonService.convertDateFormat2(shiftRequirementDetails?.shift_date, 'MM-DD-YYYY') || 'N/A'} {shiftRequirementDetails?.requirementType === "Contract Travel" && ` - ${CommonService.convertDateFormat2(shiftRequirementDetails?.shift_end_date, 'MM-DD-YYYY')}`}
                                                    </div>
                                                </div>
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-right-20 mrg-bottom-20'}>
                                                        <ImageConfig.HcpType/>
                                                    </div>
                                                    <div className={'form-minor-text'}>
                                                        {shiftRequirementDetails?.hcp_type_details?.title || "N/A"}{" "}
                                                        {shiftRequirementDetails?.hcp_speciality_details?.title &&  "(" +shiftRequirementDetails?.hcp_speciality_details?.title + ")"}
                                                    </div>
                                                </div>
                                            </>}
                                            {(shiftRequirementDetails?.requirementType === "Home Health") && <>
                                                <div className={'ts-col-lg-12 mrg-top-10'}>
                                                    <div className={'d-flex'}>
                                                        <div className={'mrg-right-15'}>
                                                            <ImageConfig.FacilityIcon/>
                                                        </div>
                                                        <div>
                                                            <Tooltip
                                                                title={shiftRequirementDetails?.hospice_details?.first_name + " " + shiftRequirementDetails?.hospice_details?.last_name || '-'}
                                                                placement={"top"}>
                                                                <div
                                                                    className={'facility-name form-minor-text'}> {shiftRequirementDetails?.hospice_details?.first_name + " " + shiftRequirementDetails?.hospice_details?.last_name || 'N/A'}
                                                                </div>
                                                            </Tooltip>
                                                            <div>
                                                                ( {shiftRequirementDetails?.hospice_details?.gender} {CommonService?.calculateAge(shiftRequirementDetails?.hospice_details?.dob)} years)
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                {/*<div className={'ts-col-lg-12 mrg-top-10'}>*/}
                                                {/*    <div className={'d-flex'}>*/}
                                                {/*        <div className={'mrg-right-15'}>*/}
                                                {/*            <ImageConfig.ClockIconSmall/>*/}
                                                {/*        </div>*/}
                                                {/*        <div className={'form-minor-text'}>*/}
                                                {/*            {CommonService?.convertMinutesToTime(shiftRequirementDetails?.hospice_details?.visit_patient_time)}*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                <div className={'ts-col-lg-12 mrg-top-10'}>
                                                    <div className={'d-flex'}>
                                                        <div className={'mrg-right-15'}>
                                                            <ImageConfig.CalenderIconSmall/>
                                                        </div>
                                                        <div className={'form-minor-text'}>
                                                            Visit Patient
                                                            By: {CommonService.convertDateFormat2(shiftRequirementDetails?.shift_date, 'MM-DD-YYYY') || 'N/A'} , {CommonService?.convertMinutesToTime(shiftRequirementDetails?.hospice_details?.visit_patient_time)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'ts-col-lg-12 mrg-top-10'}>
                                                    <div className={'d-flex'}>
                                                        <div className={'mrg-right-15'}>
                                                            <ImageConfig.HcpType/>
                                                        </div>
                                                        <div className={'form-minor-text'}>
                                                            {shiftRequirementDetails?.hcp_type_details?.title || "N/A"} (Req
                                                            For : {shiftRequirementDetails?.gender_preference} staff)
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'ts-col-lg-12 mrg-top-10'}>
                                                    <div className={'d-flex'}>
                                                        <div className={'mrg-right-15 form-minor-text'}>
                                                            <ImageConfig.LocationIcon/>
                                                        </div>
                                                        <div className={'form-minor-text'}>
                                                            {address}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>}

                                        </div>
                                        <div className={"mrg-top-10 mrg-bottom-10 pdd-top-20"}>
                                            {/*<HorizontalLineComponent/>*/}
                                            <div
                                                className={" form-header-text mrg-left-10 mrg-bottom-15"}>
                                                {shiftRequirementDetails?.requirementType === "Contract Travel" ? "Job Created For" : "Shift Details"}
                                            </div>
                                            <div className={'d-flex'}>
                                                {shiftRequirementDetails?.send_to?.is_internal_hcp &&
                                                    <div
                                                        className={'open-requirement-shift-for-chip mrg-left-10 '}>Internal
                                                        Staff</div>}
                                                {shiftRequirementDetails?.send_to?.is_float &&
                                                    <div className={'open-requirement-shift-for-chip mrg-left-10'}>Float
                                                        Staff</div>}
                                                {shiftRequirementDetails?.send_to?.specific_agencies &&
                                                    <div
                                                        className={'open-requirement-shift-for-chip mrg-left-10'}>Agencies</div>}
                                            </div>
                                        </div>
                                        {
                                            shiftRequirementDetails?.requirementType === "Home Health" && <>
                                                <div className={'ts-row mrg-top-15 mrg-left-10'}>
                                                    <div className={'ts-col-lg-5'}>
                                                        <DataLabelValueComponent label={'Phone Number'}>
                                                            {CommonService.formatPhoneNumber(shiftRequirementDetails?.hospice_details?.phone_number?.replace(/^\+1/, '')) || 'N/A'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                    <div className={'ts-col-lg-7'}>
                                                        <DataLabelValueComponent label={'Email Address'}>
                                                            {shiftRequirementDetails?.hospice_details?.email_address || "N/A"}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                </div>
                                                <div className={'ts-row  mrg-left-10'}>

                                                    <div className={'ts-col-lg-7'}>
                                                        <DataLabelValueComponent label={'Staff Speciality'}>
                                                            {shiftRequirementDetails?.hcp_speciality_details?.title || 'N/A'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div className={'mrg-left-10 mrg-top-20'}>
                                            <div
                                                className={'shift-requirement-week-day-header form-header-text '}>
                                                {shiftRequirementDetails?.requirementType === "Contract Travel" ? " Job Details" : "Additional Shift Details"}
                                            </div>
                                            <div className={"pdd-right-15 pdd-top-20 pdd-bottom-20"}>
                                                {
                                                    shiftRequirementDetails?.requirementType === "Open Shift" && <>
                                                        <div className={'ts-row'}>
                                                            <div className={'ts-col-lg-5'}>
                                                                <DataLabelValueComponent label={"Open Positions"}>
                                                                    {shiftRequirementDetails?.required_hcp_count || 0}
                                                                </DataLabelValueComponent>
                                                            </div>
                                                            <div className={'ts-col-lg-7'}>
                                                                <DataLabelValueComponent label={'Shift Rate'}>
                                                                    ${shiftRequirementDetails?.shift_rate || 0}
                                                                </DataLabelValueComponent>
                                                            </div>
                                                        </div>
                                                        <div className={'ts-row'}>
                                                            <div className={'ts-col-lg-5'}>
                                                                <DataLabelValueComponent label={'Wing'}>
                                                                    {shiftRequirementDetails?.facility_wing_details?.data?.name || 'N/A'}
                                                                </DataLabelValueComponent>
                                                            </div>
                                                            <div className={'ts-col-lg-7'}>
                                                                <DataLabelValueComponent label={'Weekend Rate'}>
                                                                    {shiftRequirementDetails?.hcp_weekend_rate ? `$ ${shiftRequirementDetails?.hcp_weekend_rate}` : 'N/A'}
                                                                </DataLabelValueComponent>
                                                            </div>
                                                        </div>
                                                        {/*<div className={'ts-row'}>*/}
                                                        {/*    <div className={'ts-col-lg-5'}>*/}
                                                        {/*        <DataLabelValueComponent label={'Differential'}>*/}
                                                        {/*            {shiftRequirementDetails?.differential_rate || 'N/A'}*/}
                                                        {/*        </DataLabelValueComponent>*/}
                                                        {/*    </div>*/}
                                                        {/*    <div className={'ts-col-lg-7'}>*/}
                                                        {/*        <DataLabelValueComponent label={'OT Pay'}>*/}
                                                        {/*            {shiftRequirementDetails?.ot_pay || 'N/A'}*/}
                                                        {/*        </DataLabelValueComponent>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}
                                                        <div className={'ts-row'}>
                                                            <div className={'ts-col-lg-5'}>
                                                                <DataLabelValueComponent label={'Rush Rate'}>
                                                                    {shiftRequirementDetails?.hcp_rush_rate ? `$${shiftRequirementDetails?.hcp_rush_rate}` : 'N/A'}
                                                                </DataLabelValueComponent>
                                                            </div>
                                                            <div className={'ts-col-lg-7'}>
                                                                <DataLabelValueComponent label={'PM Diff'}>
                                                                    {shiftRequirementDetails?.hcp_pm_diff ? `$ ${shiftRequirementDetails?.hcp_pm_diff}` : 'N/A'}
                                                                </DataLabelValueComponent>
                                                            </div>
                                                        </div>
                                                        <div className={'ts-row'}>
                                                            <div className={'ts-col-lg-5'}>
                                                                <DataLabelValueComponent label={'NOC Rate'}>
                                                                    {shiftRequirementDetails?.hcp_noc_diff ? `$${shiftRequirementDetails?.hcp_noc_diff}` : 'N/A'}
                                                                </DataLabelValueComponent>
                                                            </div>
                                                            <div className={'ts-col-lg-7'}>
                                                                <DataLabelValueComponent label={'Hazard Rate'}>
                                                                    {shiftRequirementDetails?.hcp_hazard_rate ? `$ ${shiftRequirementDetails?.hcp_hazard_rate}` : 'N/A'}
                                                                </DataLabelValueComponent>
                                                            </div>
                                                        </div>
                                                        <div className={'ts-row'}>
                                                            <div className={'ts-col-lg-12'}>
                                                                <DataLabelValueComponent label={'Notes'}>
                                                                    {shiftRequirementDetails?.note || 'N/A'}
                                                                </DataLabelValueComponent>
                                                            </div>
                                                        </div>
                                                        {shiftRequirementDetails?.requirementGalleryPics && shiftRequirementDetails?.requirementGalleryPics?.length > 0 &&
                                                            <div className={'ts-row'}>
                                                                <div className={'ts-col-lg-12'}>
                                                                    <DataLabelValueComponent label={'Documents '}>
                                                                        <div>
                                                                            {shiftRequirementDetails?.requirementGalleryPics && shiftRequirementDetails?.requirementGalleryPics?.map((file: any, index: any) => {
                                                                                return (
                                                                                    <>
                                                                                        <div
                                                                                            className={'file-holder-wrapper mrg-bottom-10 pdd-10'}>
                                                                                            <div
                                                                                                className={'underline-text icon-button'}
                                                                                                onClick={() => {
                                                                                                    const link = document.createElement('a');
                                                                                                    link.href = file?.url;
                                                                                                    link.setAttribute('download', '');

                                                                                                    document.body.appendChild(link);
                                                                                                    link.click();

                                                                                                    // Cleanup
                                                                                                    document.body.removeChild(link);
                                                                                                    // window.open(file?.url);
                                                                                                }}> Document {index + 1}</div>
                                                                                        </div>
                                                                                    </>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    </DataLabelValueComponent>
                                                                </div>
                                                            </div>}
                                                    </>
                                                }
                                                {
                                                    shiftRequirementDetails?.requirementType === "Home Health" && <>
                                                        <div className={'ts-row'}>
                                                            <div className={'ts-col-lg-12'}>
                                                                <DataLabelValueComponent label={"Open Positions"}>
                                                                    {shiftRequirementDetails?.required_hcp_count || 0}
                                                                </DataLabelValueComponent>
                                                            </div>
                                                        </div>
                                                        <div className={'ts-row'}>
                                                            {/*<div className={'ts-col-lg-5'}>*/}
                                                            {/*    <DataLabelValueComponent label={"Open Positions"}>*/}
                                                            {/*        {shiftRequirementDetails?.required_hcp_count || 0}*/}
                                                            {/*    </DataLabelValueComponent>*/}
                                                            {/*</div>*/}
                                                            <div className={'ts-col-lg-5'}>
                                                                <DataLabelValueComponent label={'Shift Rate'}>
                                                                    ${shiftRequirementDetails?.shift_rate || 0}
                                                                </DataLabelValueComponent>
                                                            </div>
                                                            <div className={'ts-col-lg-7'}>
                                                                <DataLabelValueComponent label={'Language Preferences'}>
                                                                    {/* {shiftRequirementDetails?.language || "_"}*/}
                                                                    {shiftRequirementDetails?.languages ? shiftRequirementDetails?.languages?.filter((language: any) => language !== null)
                                                                        .join(', ') : "N/A"}
                                                                </DataLabelValueComponent>
                                                            </div>

                                                        </div>
                                                        <div className={'ts-row'}>
                                                            <div className={'ts-col-lg-5'}>
                                                                <DataLabelValueComponent label={'No. of Visits'}>
                                                                    {shiftRequirementDetails?.number_of_visit || "N/A"}
                                                                </DataLabelValueComponent>
                                                            </div>
                                                            <div className={'ts-col-lg-7'}>
                                                                <DataLabelValueComponent label={'MRN'}>
                                                                    {shiftRequirementDetails?.hospice_details?.mrn || "N/A"}
                                                                </DataLabelValueComponent>
                                                            </div>
                                                        </div>
                                                        <div className={'ts-row'}>
                                                            <div className={'ts-col-lg-5'}>
                                                                <DataLabelValueComponent label={'Latitude'}>
                                                                    {shiftRequirementDetails?.hospice_details?.location?.coordinates[1] || "N/A"}
                                                                </DataLabelValueComponent>
                                                            </div>
                                                            <div className={'ts-col-lg-7'}>
                                                                <DataLabelValueComponent label={'Longitude'}>
                                                                    {shiftRequirementDetails?.hospice_details?.location?.coordinates[0] || "N/A"}
                                                                </DataLabelValueComponent>
                                                            </div>
                                                        </div>

                                                        {shiftRequirementDetails?.requirementGalleryPics && shiftRequirementDetails?.requirementGalleryPics?.length > 0 &&
                                                            <div className={'ts-row'}>
                                                                <div className={'ts-col-lg-12'}>
                                                                    <DataLabelValueComponent label={'Documents '}>
                                                                        <div>
                                                                            {shiftRequirementDetails?.requirementGalleryPics && shiftRequirementDetails?.requirementGalleryPics?.map((file: any, index: any) => {
                                                                                return (
                                                                                    <>
                                                                                        <div
                                                                                            className={'file-holder-wrapper mrg-bottom-10 pdd-10'}>
                                                                                            <div
                                                                                                className={'underline-text icon-button'}
                                                                                                onClick={() => {
                                                                                                    const link = document.createElement('a');
                                                                                                    link.href = file?.url;
                                                                                                    link.setAttribute('download', '');

                                                                                                    document.body.appendChild(link);
                                                                                                    link.click();

                                                                                                    // Cleanup
                                                                                                    document.body.removeChild(link);
                                                                                                    // window.open(file?.url);
                                                                                                }}> Document {index + 1}</div>
                                                                                        </div>
                                                                                    </>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    </DataLabelValueComponent>
                                                                </div>
                                                            </div>}
                                                    </>
                                                }
                                                {
                                                    shiftRequirementDetails?.requirementType === "Contract Travel" && <>
                                                        <div className={'ts-row'}>
                                                            <div className={'ts-col-lg-5'}>
                                                                <DataLabelValueComponent label={"# of positions"}>
                                                                    {shiftRequirementDetails?.required_hcp_count || 0}
                                                                </DataLabelValueComponent>
                                                            </div>
                                                            <div className={'ts-col-lg-7'}>
                                                                <DataLabelValueComponent label={'Hours per Shift'}>
                                                                {shiftRequirementDetails?.shift_duration || 0} hours
                                                                </DataLabelValueComponent>
                                                            </div>
                                                        </div>
                                                        <div className={'ts-row'}>
                                                            <div className={'ts-col-lg-5'}>
                                                                <DataLabelValueComponent label={'# of Days per Week'}>
                                                                    {shiftRequirementDetails?.day_per_week || 'N/A'}
                                                                </DataLabelValueComponent>
                                                            </div>
                                                            <div className={'ts-col-lg-7'}>
                                                                <DataLabelValueComponent label={'Job Type'}>
                                                                    {shiftRequirementDetails?.job_type || "N/A"}
                                                                </DataLabelValueComponent>
                                                            </div>
                                                        </div>

                                                        <div className={'ts-row'}>
                                                            <div className={'ts-col-lg-12'}>
                                                                <DataLabelValueComponent label={'# of Weeks'}>
                                                                    {shiftRequirementDetails?.no_of_weeks || "N/A"}
                                                                </DataLabelValueComponent>
                                                            </div>
                                                        </div>
                                                        {shiftRequirementDetails?.travel_per_diems &&
                                                            <>
                                                        <div
                                                            className={'shift-requirement-week-day-header form-header-text mrg-bottom-10'}
                                                        >
                                                            Rates
                                                        </div>
                                                        <div className={'ts-row'}>
                                                            <div className={'ts-col-lg-4'}>
                                                                <DataLabelValueComponent label={'Type'}>

                                                                    {
                                                                        shiftRequirementDetails?.travel_per_diems?.map((item: any, index: any) => {
                                                                            return (
                                                                                <div className={'mrg-bottom-10'}>
                                                                                    {item?.type}
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }

                                                                </DataLabelValueComponent>
                                                            </div>
                                                            <div className={'ts-col-lg-4'}>
                                                                <DataLabelValueComponent
                                                                    label={'Rate'}
                                                                >
                                                                    {
                                                                        shiftRequirementDetails?.travel_per_diems?.map((item: any, index: any) => {
                                                                            return (
                                                                                <div
                                                                                    className={'d-flex ts-justify-content-between mrg-bottom-10'}>
                                                                                    {item?.rate ?
                                                                                    <div>
                                                                                        ${item?.rate} {index === 0 ? "/hr" : "/day"}
                                                                                    </div>:
                                                                                    <div> N/A </div>
                                                                                    }
                                                                                    {/*<div*/}
                                                                                    {/*    className={'days-rate-text-orientation '}>*/}
                                                                                    {/*    {`(${shiftRequirementDetails?.day_per_week}days  *  $${item?.rate})`}*/}
                                                                                    {/*</div>*/}
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }

                                                                </DataLabelValueComponent>
                                                            </div>
                                                            <div className={'ts-col-lg-4'}>
                                                                <DataLabelValueComponent
                                                                    label={'Rate/week'}
                                                                >
                                                                    {
                                                                        shiftRequirementDetails?.travel_per_diems?.map((item: any, index: any) => {
                                                                            return (
                                                                                <div
                                                                                    className={'d-flex ts-justify-content-between mrg-bottom-10'}>
                                                                                    {item?.week_rate ?
                                                                                    <div>
                                                                                        ${item?.week_rate} /week
                                                                                    </div>:
                                                                                    <div> N/A </div>
                                                                                    }
                                                                                    {/*<div*/}
                                                                                    {/*    className={'days-rate-text-orientation '}>*/}
                                                                                    {/*    {`(${shiftRequirementDetails?.day_per_week}days  *  $${item?.rate})`}*/}
                                                                                    {/*</div>*/}
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }

                                                                </DataLabelValueComponent>
                                                            </div>
                                                        </div>
                                                            </>}
                                                        {shiftRequirementDetails?.cancel_policy &&
                                                        <div className={'ts-row'}>
                                                            <div className={'ts-col-lg-12'}>
                                                                <DataLabelValueComponent label={'Cancel Policy'}>
                                                                    <div
                                                                        dangerouslySetInnerHTML={{__html: shiftRequirementDetails?.cancel_policy?.replace(/\n/g, '<br/>')}}/>
                                                                </DataLabelValueComponent>
                                                            </div>
                                                        </div>
                                                        }
                                                        {shiftRequirementDetails?.staff_requirement &&
                                                        <div className={'ts-row'}>
                                                            <div className={'ts-col-lg-12'}>
                                                                <DataLabelValueComponent label={'Staff Requirements'}>
                                                                    <div
                                                                        dangerouslySetInnerHTML={{__html: shiftRequirementDetails?.staff_requirement?.replace(/\n/g, '<br/>')}}/>
                                                                </DataLabelValueComponent>
                                                            </div>
                                                        </div>
                                                        }
                                                        {shiftRequirementDetails?.job_details &&
                                                        <div className={'ts-row'}>
                                                            <div className={'ts-col-lg-12'}>
                                                                <DataLabelValueComponent label={'Job Details'}>
                                                                    <div
                                                                        dangerouslySetInnerHTML={{__html: shiftRequirementDetails?.job_details?.replace(/\n/g, '<br/>')}}/>
                                                                </DataLabelValueComponent>
                                                            </div>
                                                        </div>
                                                        }
                                                {shiftRequirementDetails?.job_benefits &&
                                                        <div className={'ts-row'}>
                                                            <div className={'ts-col-lg-12'}>
                                                                <DataLabelValueComponent label={'Job Benefits'}>
                                                                    <div
                                                                        dangerouslySetInnerHTML={{__html: shiftRequirementDetails?.job_benefits?.replace(/\n/g, '<br/>')}}/>
                                                                </DataLabelValueComponent>
                                                            </div>
                                                        </div>
                                                }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>


                                    <div className={"shift-application-wrapper"}>
                                        <div>
                                            <div>
                                                <TabsWrapperComponent>
                                                    <div className={'d-flex ts-justify-content-sm-between'}>
                                                        <div>
                                                            <TabsComponent value={activeTab}
                                                                           allowScrollButtonsMobile={false}
                                                                           onUpdate={(e: any, v: any) => {
                                                                               setActiveTab(v);
                                                                               setFilterData({
                                                                                   ...filterData,
                                                                                   status: v
                                                                               });
                                                                           }}>
                                                                <TabComponent label={
                                                                    <span className={'d-flex align-items-center'}>Requests<span
                                                                        className={'count-box'}>{shiftRequirementDetails?.applications_request_count}</span></span>}
                                                                              value={"pending"}
                                                                />
                                                                <TabComponent label={
                                                                    <span className={'d-flex align-items-center'}>Approved<span
                                                                        className={'count-box'}>{shiftRequirementDetails?.applications_approved_count
                                                                    }</span></span>} value={"approved"}/>
                                                                <TabComponent label={
                                                                    <span className={'d-flex align-items-center'}>Rejected<span
                                                                        className={'count-box'}>{shiftRequirementDetails?.applications_rejected_count
                                                                    }</span></span>} value={"rejected"}/>
                                                            </TabsComponent>
                                                        </div>
                                                        {((!selectedAgencyForHcp.selectedAgency && shiftRequirementDetails?.status !== "filled" && shiftRequirementDetails?.status !== "cancelled") && (shiftRequirementDetails?.send_to?.is_internal_hcp || shiftRequirementDetails?.send_to?.is_float)) &&
                                                            <div>
                                                                <ButtonComponent onClick={() => {
                                                                    setOpenDrawer(true);
                                                                }}
                                                                                 color={'download-button'}
                                                                                 prefixIcon={
                                                                                     <ImageConfig.AddOutlinedIcon/>}>
                                                                    Assign Internal Staff
                                                                </ButtonComponent>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div
                                                        className={'mrg-top-10'}>
                                                        {(shiftRequirementDetails?.status !== "cancelled" && shiftRequirementDetails?.status !== "filled" && shiftRequirementDetails?.send_to?.specific_agencies) &&
                                                            <div
                                                                className={'open-details-agency-selection-wrapper mrg-left-15 pdd-left-20 pdd-right-20 mrg-bottom-10 pdd-10'}>
                                                                <div className={'open-details-agency-text'}>Assign Staff
                                                                    from
                                                                    agencies
                                                                </div>
                                                                <div className={'d-flex'}>
                                                                    <SelectDropdownComponent
                                                                        noDataFoundText={"No Agency Found"}
                                                                        navigation={() => {
                                                                            // setFormScreenNavigation("add-new-agency");
                                                                        }} buttonRequired={false}
                                                                        placeholder={"Select Agency"}
                                                                        searchMode={"clientSide"}
                                                                        value={selectedAgencyForHcp.selectedAgency || null}
                                                                        options={modifiedData}
                                                                        valueExtractor={item => item.code}
                                                                        searchable={true}
                                                                        onUpdate={
                                                                            (value: any) => {
                                                                                console.log(facilityAgencyList);
                                                                                setSelectedAgency({
                                                                                    ...selectedAgencyForHcp,
                                                                                    selectedAgency: value,
                                                                                });
                                                                            }
                                                                        }/>
                                                                    {selectedAgencyForHcp.selectedAgency &&
                                                                        <div className={'mrg-left-10'}>
                                                                            <ButtonComponent onClick={() => {
                                                                                setOpenDrawer(true);
                                                                            }}
                                                                                             color={'download-button'}
                                                                                             prefixIcon={
                                                                                                 <ImageConfig.AddOutlinedIcon/>}>
                                                                                Assign Staff
                                                                            </ButtonComponent>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <TabContentComponent value={activeTab}
                                                                         selectedTab={filterData?.status}>
                                                        <div
                                                            className={'open-shift-list-component mrg-left-15'}>
                                                            <div className="open-shift-table-component">
                                                                {/*<TableWrapperComponent*/}
                                                                {/*    url={APIConfig.HCP_ASSIGNED_TO_SHIFT.URL(requirementId)}*/}
                                                                {/*    method={APIConfig.HCP_ASSIGNED_TO_SHIFT.METHOD}*/}
                                                                {/*    isPaginated={true}*/}
                                                                {/*    extraPayload={filterData}*/}
                                                                {/*    noDataText={`No ${activeTab} Shift Application Found.`}*/}
                                                                {/*    columns={ShiftsListColumn}/>*/}
                                                                <AntTableComponent
                                                                        url={APIConfig.HCP_ASSIGNED_TO_SHIFT.URL(requirementId)}
                                                                        method={APIConfig.HCP_ASSIGNED_TO_SHIFT.METHOD}
                                                                        extraPayload={filterData}
                                                                        noDataText={`No ${activeTab} Shift Application Found.`}
                                                                        columns={ShiftsListColumn}
                                                                />
                                                            </div>
                                                        </div>
                                                    </TabContentComponent>
                                                </TabsWrapperComponent>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </>

                            }
                        </>
                    }
                </div>
            </div>


        </>
    );
};

export default OpenShiftDetailsComponent;
