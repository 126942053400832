import "./ShiftFilterDrawerComponent.scss";
import {ImageConfig} from "../../../constants";
import React, {useCallback, useState} from "react";
import HorizontalLineComponent
    from "../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import {useSelector} from "react-redux";
import {IRootReducerState} from "../../../store/reducers";
import SearchCheckboxComponent from "../../../shared/components/search-checkbox/SearchCheckboxComponent";
import {open_shift_status, shift_type_filter} from "../../../data";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-multi-date-picker/styles/layouts/mobile.css";
import moment from "moment";




interface ShiftFilterDrawerComponentProps{
    setOpenDrawer?: any;
    setFilterData: any;
    filterData: any;
    setSelectedFilterCount?:any;
    dateRange?:any;
    setDateRange?:any;
    selectedFacilityData?:any;
    setSelectedFacilityData?:any;
    selectedOpenShiftStatus?:any;
    setSelectedOpenShiftStatus?:any;
    selectedHcpTypeData?:any;
    setSelectedHcpTypeData?:any;
    selectedShiftData?:any;
    setSelectedShiftData?:any;
}


const ShiftFilterDrawerComponent=(props:ShiftFilterDrawerComponentProps)=>{
    const {
        filterData,
        setFilterData,
        setOpenDrawer,
        setSelectedFilterCount,
        dateRange,
        setDateRange,
        selectedFacilityData,
        setSelectedFacilityData,
        selectedHcpTypeData,
        setSelectedHcpTypeData,
        selectedShiftData,
        setSelectedShiftData,
        selectedOpenShiftStatus,
        setSelectedOpenShiftStatus,
    }=props;
    const [startDate, endDate] = dateRange;
    const [isOpen, setIsOpen] = useState(false);
    const {HcpTypesList} = useSelector((state: IRootReducerState) => state.meta);
    const {facilityList} = useSelector((state: IRootReducerState) => state.staticData);


    const updateFilters = useCallback((updatedFilterData: any) => {
        let selectedFilter = 0;

        // Date filters
        if (updatedFilterData.start_date) selectedFilter++;
        if (updatedFilterData.end_date) selectedFilter++;

        // Array filters - check if it's an array before counting
        if (Array.isArray(updatedFilterData.hcp_types)) {
            selectedFilter += updatedFilterData.hcp_types.length;
        }
        if (Array.isArray(updatedFilterData.facility_ids)) {
            selectedFilter += updatedFilterData.facility_ids.length;
        }
        if (Array.isArray(updatedFilterData.shift_types)) {
            selectedFilter += updatedFilterData.shift_types.length;
        }
        if (Array.isArray(updatedFilterData.open_status_type)) {
            selectedFilter += updatedFilterData.open_status_type.length;
        }

        setSelectedFilterCount(selectedFilter);
        setFilterData(updatedFilterData);
    }, [setFilterData, setSelectedFilterCount]);

    const handleDateRangeSelection = useCallback((dateRange: [Date | null, Date | null]) => {
        const [newStartDate, newEndDate] = dateRange || [];

        const startDateFormatted = newStartDate
            ? moment(newStartDate).format("YYYY-MM-DD")
            : null;
        const endDateFormatted = newEndDate
            ? moment(newEndDate || newStartDate).format("YYYY-MM-DD")
            : null;

        const updatedFilterData = {
            ...filterData,
        };
        if (startDateFormatted) {
            updatedFilterData.start_date = startDateFormatted;
            // Clear the end_date if the user picks a new start_date
            if (filterData.start_date !== startDateFormatted) {
                delete updatedFilterData.end_date;
            }
        }

        if(endDateFormatted){
            updatedFilterData.end_date=endDateFormatted;
        }

        // Remove date fields if no date is selected
        if (!startDateFormatted && !endDateFormatted) {
            delete updatedFilterData.start_date;
            delete updatedFilterData.end_date;
        }

        // Update filters and selected filter count dynamically
        updateFilters(updatedFilterData);
    }, [filterData, updateFilters]);


    const handleHcpTypeSelection = useCallback((selectedOptions:any) => {
        const updatedFilterData = { ...filterData, hcp_types: selectedOptions?.map((item:any) => item.code) };
        updateFilters(updatedFilterData);
        setSelectedHcpTypeData(selectedOptions);
    }, [filterData, updateFilters,setSelectedHcpTypeData]);

    const handleOpenShiftStatusSelection = useCallback((selectedOptions:any) => {
        const updatedFilterData = { ...filterData, open_status_type: selectedOptions?.map((item:any) => item.code) };
        updateFilters(updatedFilterData);
        setSelectedOpenShiftStatus(selectedOptions);
    }, [filterData, updateFilters,setSelectedOpenShiftStatus]);


    const handleShiftTypeSelection = useCallback((selectedOptions:any) => {
        const updatedFilterData = { ...filterData, shift_types: selectedOptions?.map((item:any) => item.code) };
        updateFilters(updatedFilterData);
        setSelectedShiftData(selectedOptions);
    }, [filterData, updateFilters,setSelectedShiftData]);


    const handleFacilitySelect = useCallback((selectedOptions:any) => {
        const updatedFilterData = { ...filterData, facility_ids: selectedOptions?.map((item:any) => item.code) };
        updateFilters(updatedFilterData);
        setSelectedFacilityData(selectedOptions);
    }, [filterData, updateFilters,setSelectedFacilityData]);



    const clearAllFilter = useCallback(() => {
        setDateRange([null, null]);
        setSelectedHcpTypeData([]);
        setSelectedShiftData([]);
        setSelectedFacilityData([]);
        setSelectedOpenShiftStatus([]);
        setSelectedFilterCount(0);
        delete filterData.shift_types;
        delete filterData.hcp_types;
        delete filterData.start_date;
        delete filterData.end_date;
        delete filterData.hcp_types;
        delete filterData.facility_ids;
        delete filterData.open_status_type;
        setFilterData({
            ...filterData
        });
    }, [filterData,
        setFilterData,
        setDateRange,
        setSelectedFacilityData,
        setSelectedFilterCount,
        setSelectedHcpTypeData,
        setSelectedOpenShiftStatus,
        setSelectedShiftData
    ]);



    return (
        <div className={'scheduler-filter-drawer-container'}>
            <div className={'d-flex justify-content-space-between pdd-20'}>
                <div className={'d-flex ts-align-items-center'}>
                    <div className={'form-header-text'}> Shift Filters
                    </div>
                    <ButtonComponent
                        type={'reset'}
                        variant={'link'}
                        style={{
                            color:'#0DB9D8'
                        }}
                        className={'mrg-right-20'}
                        onClick={clearAllFilter}
                    >
                        Clear All
                    </ButtonComponent>
                </div>
                <div className={'d-flex'}>
                    <div className={'icon-button'} onClick={() => {
                        setOpenDrawer(false);
                    }}>
                        <ImageConfig.CrossIcon/>
                    </div>
                </div>
            </div>
            <HorizontalLineComponent/>
            <div className={'scheduler-filter-container'}>
                <div className={''}>
                    <DatePicker
                        dateFormat="MM/dd/yyyy"
                        placeholderText="Shift Date"
                        className="custom-input"
                        selectsRange={true}
                        startDate={startDate && new Date(startDate)}
                        endDate={endDate && new Date(endDate)}
                        onInputClick={() => {
                            setIsOpen(!isOpen);
                        }}
                        onClickOutside={() => {
                            setIsOpen(false);
                        }}
                        open={isOpen}
                        onChange={(update) => {
                            setDateRange(update);
                            handleDateRangeSelection(update); // Call the reusable function
                        }}
                        isClearable={true}
                    />
                </div>
                {filterData?.status === 'open' &&
                    <div>
                        <SearchCheckboxComponent
                            placeholder={'Select Status Type'}
                            options={open_shift_status}
                            noDataText={"No Open Shift Status"}
                            selectedOptions={selectedOpenShiftStatus}
                            onChange={handleOpenShiftStatusSelection}
                        />
                    </div>
                }
                <div>
                    <SearchCheckboxComponent
                        options={facilityList}
                        placeholder={"Select Facility"}
                        noDataText={"No Facility"}
                        selectedOptions={selectedFacilityData}
                        onChange={handleFacilitySelect}
                    />
                </div>
                <div>
                    <SearchCheckboxComponent
                        options={HcpTypesList}
                        placeholder={"Select Staff Type"}
                        noDataText={"No Staff Type"}
                        selectedOptions={selectedHcpTypeData}
                        onChange={handleHcpTypeSelection}
                    />
                </div>
                <div>
                    <SearchCheckboxComponent
                        options={shift_type_filter}
                        placeholder={"Select Shift Type"}
                        noDataText={"No Shift Type"}
                        selectedOptions={selectedShiftData}
                        onChange={handleShiftTypeSelection}
                    />
                </div>
            </div>
        </div>
    )
}

export default ShiftFilterDrawerComponent;