import "./AgencyListComponent.scss";
// import TableWrapperComponent from "../../../shared/components/table-wrapper/TableWrapperComponent";
import {APIConfig, ImageConfig, Misc} from "../../../constants";
// import {ITableColumn} from "../../../shared/models/table.model";
import {CommonService} from "../../../shared/services";
// import TagComponent from "../../../shared/components/tag/TagComponent";
import React, {useCallback, useEffect, useState} from "react";
import SearchComponent from "../../../shared/components/search/SearchComponent";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import LinkComponent from "../../../shared/components/link/LinkComponent";
import ToolTipComponent from "../../../shared/components/tool-tip/ToolTipComponent";
import {useDispatch,useSelector} from "react-redux";
import {setCurrentNavParams} from "../../../store/actions/navigation.action";
import {IAPIResponseType} from "../../../shared/models/api.model";
import CustomToggleComponent
    from "../../../shared/components/custom-toogle-using-button-component/CustomToggleComponent";
import {IRootReducerState} from "../../../store/reducers";
import {TableColumnsType} from "antd";
import AntTableComponent from "../../../shared/components/antd-table-component/AntTableComponent";

interface AgencyListComponentProps {

}

const AgencyListComponent = (props: AgencyListComponentProps) => {
    const [filterData, setFilterData] = useState<any>({
        search: '',
        sort: {},
    });
    const dispatch = useDispatch();
    const {currentUser}=useSelector((state:IRootReducerState)=>state.account);

    useEffect(() => {
        dispatch(setCurrentNavParams('Agency') as any);
    }, [dispatch]);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);


    const handleDownloadAgency = useCallback(() => {
        setIsDownloading(true);
        const payload: any = {};
        const {status} = filterData;
        payload.status = [status];
        let apiCall: any = {};
        apiCall = CommonService._agency.downloadAgencyDataCSVAPICall(filterData);

        apiCall.then((response: IAPIResponseType<any>) => {
            if (response?.data) {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                const link = document.createElement("a");
                link?.setAttribute("href", response?.data);
                document.body.appendChild(link);
                link.click();
                setIsDownloading(false);
            } else {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "error");
                setIsDownloading(false);
            }

        })
            .catch((error: any) => {
                setIsDownloading(false);
            }).finally(() => {

        });
    }, [filterData]);

    const onAgencyActivateDeactivate = useCallback((item: any) => {
        CommonService.onConfirm(
            {
                confirmationTitle: `${item?.is_active ? 'Deactivate' : 'Activate'}  Agency`,
                image: item?.is_active ? ImageConfig.AgencyDeactivateIcon : ImageConfig.AgencyActivateIcon,
                confirmationDescription: `Are you sure you want to ${item?.is_active ? 'deactivate' : 'activate'}  the
    Agency?`,
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                activationType: item?.is_active,
                yes: {
                    color: "error",
                    text: `Yes, ${item?.is_active ? 'Deactivate' : 'Activate'}`,
                    variant: "contained",
                }
            }
        ).then((result: any) => {
            const payload: any = {};
            payload.is_active = !item?.is_active;
            CommonService._agency.ActivateDeactivateAgencyFromList(item?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                    setFilterData({});
                }).catch((error: any) => {
                CommonService._alert.showToast(error?.error || "Error", "error");
            });
        });
    }, []);


    const AgencyListColumn:TableColumnsType=[
        {
            title:"Agency Name",
            dataIndex: 'name',
            width: 250,
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (text: any) => {
                return <div className={'agency-details-wrapper'}>
                    {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                    <div className={'facility-name-tower-wrapper'}>
                        {
                            text?.length > 20 ?
                                <ToolTipComponent
                                    tooltip={text}
                                    showArrow={true}
                                    position={"top"}
                                >
                                    <div className={"ellipses-for-table-data property-name"}>
                                        {text}
                                    </div>
                                </ToolTipComponent> :
                                <div className="facility-name">
                                    {text || 'N/A'}
                                </div>
                        }
                    </div>
                </div>;
            }
        },
        {
            title:"Facility Count",
            dataIndex: 'facilities_count',
            // sortable: true,
            width: 140,
            sorter: (a, b) => a.facilities_count - b.facilities_count,
            render: (text: any) => {
                return <div className={'agency-details-wrapper'}>
                    <span>{text || 0}</span>
                </div>;
            }
        },

        {
            title:"Location",
            dataIndex: 'address',
            width: 150,
            sorter: (a, b) => (a.address.city || "").localeCompare(b.address.city || ""),
            render: (item: any) => {
                return <div className={'hcp-details-wrapper'}>
                    <div className={'hcp-name-tower-wrapper'}>
                        {
                            (item?.city)?.length > 15 ?
                                <ToolTipComponent
                                    tooltip={item?.city}
                                    showArrow={true}
                                    position={"top"}
                                >
                                    <div className={"ellipses-for-table-data property-name"}>
                                        {item?.city}
                                    </div>
                                </ToolTipComponent> :
                                <div className="hcp-name">
                                    {item?.city || 'N/A'}
                                </div>
                        }
                    </div>
                </div>;
            }
        },
        {
            title:"Onboarded On",
            key: 'created_at',
            dataIndex: 'created_at',
            width: 160,
            align: 'left',
            sorter: (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
            render: (item: any) => {
                if (item) {
                    return <>{CommonService.convertDateFormat2(item, "MM-DD-YYYY")}</>;
                } else {
                    return <>N/A</>;
                }
            }
        },
        {
            title:"Admin Name",
            key: 'admin_details',
            dataIndex: 'admin_details',
            width: 250,
            sorter: (a, b) =>
                (a.admin_details?.first_name + a.admin_details?.last_name || "").localeCompare(
                    b.admin_details?.first_name + b.admin_details?.last_name || ""
                ),
            render: (admin: any) => {
                return <div className={'agency-details-wrapper'}>

                    <div className={'facility-name-tower-wrapper'}>
                        {
                            (admin?.first_name + " " + admin?.last_name)?.length > 20 ?
                                <ToolTipComponent
                                    tooltip={admin?.first_name + " " + admin?.last_name}
                                    showArrow={true}
                                    position={"top"}
                                >
                                    <div className={"ellipses-for-table-data property-name"}>
                                        {admin?.first_name + " " + admin?.last_name}
                                    </div>
                                </ToolTipComponent> :
                                <div className="agency-name">
                                    {admin?.first_name + " " + admin?.last_name || 'N/A'}
                                </div>
                        }
                    </div>
                </div>;
            }
        },
        {
            title:"Admin Contact No",
            key: 'admin_details',
            dataIndex: 'admin_details',
            width: 170,
            // sorter: (a, b) =>
            //     (a.admin_details?.contact_number || "").localeCompare(b.admin_details?.contact_number || ""),
            render: (admin: any) => {
                return (
                    <>
                        {CommonService.formatPhoneNumber( admin?.contact_number?.replace(/^\+1/, '')) || 'N/A'}
                    </>
                );
            }
        },

        {
            title: 'Active/Inactive',
            width: 140,
            render: (item: any) => {
                return <div className={'display-center'}>
                    <CustomToggleComponent defaultOn={item?.is_active} toggleHandler={() => {
                        onAgencyActivateDeactivate(item);
                    }}/>
                </div>;
            }
        },
        {
            title: "View Details",
            align: 'center',
            width: 130,
            render: (item: any) => {
                if (item?._id) {
                    return <LinkComponent
                        route={CommonService._routeConfig.AgencyDetailsRoute(item?.admin_details?.agency_id)}>
                        <ImageConfig.ArrowRightOutlinedIcon color={"#4E535F"}/>
                    </LinkComponent>;
                }
            }
        }
    ]

    return (
        <>
            <div className={'agency-list-component header-screen mrg-top-25 mrg-right-30 mrg-left-25'}>
                <div className="list-screen-header-wrapper">
                    <div className="list-screen-header">
                        {/*<div className={'mrg-bottom-5 home-text'}>Home</div>*/}
                        <div className="list-screen-header-title-and-count d-flex">
                            <div className={'mrg-right-10'}>
                                <ImageConfig.AgencyModuleHeaderIcon/>
                            </div>
                            <div className="list-screen-header-title">Agency</div>
                        </div>
                    </div>
                    <div className="list-options">
                        <div className={"mrg-top-20"}>
                            <SearchComponent
                                placeholder="Search Agency Name"
                                value={filterData.search}
                                onSearchChange={(value) => {
                                    setFilterData({...filterData, search: value, page: 1});
                                }}
                            />
                        </div>
                        <ButtonComponent color={'download-button'} variant={"contained"}
                                         isLoading={isDownloading}
                                         onClick={handleDownloadAgency}>
                            Download CSV
                        </ButtonComponent>
                        {currentUser?.role !== 'super_admin' &&
                            <LinkComponent route={CommonService._routeConfig.AddAgencyRoute()}>
                                <ButtonComponent color={'primary'} prefixIcon={<ImageConfig.AddOutlinedIcon/>}>
                                    Add/Link Agency
                                </ButtonComponent>
                            </LinkComponent>
                        }
                    </div>
                </div>
            </div>
            <div className={'facility-list-component mrg-top-10 mrg-left-25 mrg-right-30'}>
                <div className="agency-table-component">
                    {/*<TableWrapperComponent url={APIConfig.AGENCY_LIST.URL}*/}
                    {/*                       method={APIConfig.AGENCY_LIST.METHOD}*/}
                    {/*                       isPaginated={true}*/}
                    {/*                       extraPayload={filterData}*/}
                    {/*                       noDataText={filterData.search ? 'Oops! It seems like there are no Agency available for the name you have searched.' : 'No Agency Data Found.'}*/}
                    {/*                       columns={AgencyListColumn}*/}
                    {/*/>*/}
                    <AntTableComponent
                        url={APIConfig.AGENCY_LIST.URL}
                        method={APIConfig.AGENCY_LIST.METHOD}
                        extraPayload={filterData}
                        isRowSelection={false}
                        columns={AgencyListColumn}
                    />
                </div>
            </div>
        </>
    );

};

export default AgencyListComponent;
